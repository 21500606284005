import React from "react"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"

// import app components
import { Edges, Spacer } from "components"
import Form from "components/form"
import pattern from "images/swash.png"

const Newsletter = props => {
  const { headline, formId, ...rest } = props

  return (
    <Container {...rest}>
      <Spacer pt={{ xs: 30, sm: 60 }} pb={{ xs: 30, sm: 60 }}>
        <Edges size="md">
          <Grid container justify="space-between" alignItems="center">
            {headline && <Typography variant="h3" color="inherit" children={headline} />}
            {formId && <StyledForm formId={formId} hideTitle />}
          </Grid>
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled.div`
  background-color: #000;
  background-image: url(${pattern});
  background-position: center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  color: #fff;
  text-align: center;
`

const StyledForm = styled(Form)`
  && {
    width: 100%;
    margin-top: 20px;

    @media (min-width: 800px) {
      width: auto;
      margin-top: 0;
    }

    .gravityForm {
      display: flex;
    }

    .field {
      margin-bottom: 0;
      width: 100%;

      input {
        color: #fff;
      }

      @media (min-width: 800px) {
        width: 300px;
      }
    }

    button {
      background-color: #fff;
      border: 1px solid #fff;
      color: #000;
    }
  }
`

export default Newsletter
