import React from "react"

// import flexible content modules
import Accordion from "./Accordion"
import Boats from "./Boats"
import Carousel from "./Carousel"
import DealerLocator from "./DealerLocator"
import Downloads from "./Downloads"
import Events from "./Events"
import Features from "./Features"
import Form from "./Form"
import Gallery from "./Gallery"
import Headline from "./Headline"
import Hero from "./Hero"
import ImageLinks from "./ImageLinks"
import Newsletter from "./Newsletter"
import News from "./News"
import SocialFeed from "./SocialFeed"
import Text from "./Text"
import TextImage from "./TextImage"
import Testimonials from "./Testimonials"

export default props => {
  const { rows } = props

  if (!!rows) {
    return rows
      .filter(o => o !== null)
      .map(({ __typename, ...rowData }, index) => {
        const type = __typename.split("_").slice(-1)[0]
        const components = {
          Accordion,
          Boats,
          Carousel,
          Downloads,
          DealerLocator,
          Events,
          Features,
          Form,
          Gallery,
          Headline,
          Hero,
          ImageLinks,
          Newsletter,
          News,
          SocialFeed,
          Text,
          TextImage,
          Testimonials
        }
        const Component = components[type]
        return Component && <Component key={index} {...rowData} />
      })
  }
}
