import React, { useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"
import { Typography } from "@material-ui/core"
import YouTube from "react-youtube"

// import app components
import { BackgroundImage, Edges, Button, Spacer } from "components"
import BackgroundVideo from "components/backgroundVideo"
import { useStore } from "store"
import { headerHeight, menuBreakpoint } from "components/header/Header"

const Hero = (props) => {
  const {
    contentAlignment,
    description,
    button,
    topLabel,
    headline,
    headlineStyle,
    image,
    video,
    height,
    title,
    mediaVariant,
    videoUpload,
    ...rest
  } = props

  let videoId = !!video ? video.includes("youtu") && video.substring(video.lastIndexOf("/") + 1) : ""

  if (videoId.includes(`watch?v=`)) {
    videoId = videoId.replace(`watch?v=`, ``)
  }

  const vidRef = useRef(null)
  const [buttonTitle, setButtonTitle] = useState("Pause")


  const [dispatch] = useStore()

  const showLightBox = (url) => {
    dispatch({
      type: "SET_LIGHTBOX",
      payload: {
        open: true,
        slide: 0,
        slides: [{ link: { url } }],
        options: {
          thumbs: false
        }
      }
    })
  }

  const handlePauseYoutube = () => {
    if (buttonTitle === "Pause") {
      setButtonTitle("Play")
      vidRef.current?.internalPlayer?.pauseVideo()
    } else {
      setButtonTitle("Pause")
      vidRef.current?.internalPlayer?.playVideo()
    }
  }

  useEffect(() => {
    if (typeof window !== `undefined` && vidRef.current) {
      document.getElementById("player").tabIndex = "-1"
    }
  }, [])

  return (
    <Container height={height || "full"} {...rest} tabIndex={"-1"}>
      <StyledEdges size="md" textAlign={contentAlignment}>
        <Content textAlign={contentAlignment}>
          {topLabel && (
            <TopLabel color="inherit" textAlign={contentAlignment}>
              <span>{topLabel}</span>
            </TopLabel>
          )}

          {headline && <Typography variant={headlineStyle} children={headline} color="inherit" />}

          {description && (
            <Spacer mt={10}>
              <Typography children={description} color="inherit" />
            </Spacer>
          )}

          {button && (
            <Spacer mt={30}>
              <Button
                to={button.url.includes("youtu") ? null : button.url}
                onClick={() => button.url.includes("youtu") && showLightBox(button.url)}
                children={button.title}
                target={button.target}
                variant="outlined"
                color="secondary"
              />
            </Spacer>
          )}
        </Content>
      </StyledEdges>

      {image && <BackgroundImage image={image} />}

      {mediaVariant === "file"
        ? (videoUpload?.localFile?.publicURL || videoUpload?.mediaItemUrl) && (
            <VideoBackground>
              <BackgroundVideo src={videoUpload?.localFile?.publicURL || videoUpload?.mediaItemUrl} />
            </VideoBackground>
          )
        : video && (
            <VideoBackground>
              <TabButtonPause onClick={handlePauseYoutube}>{`${buttonTitle}`}</TabButtonPause>

              <VideoForeground>
                <YouTube
                  videoId={videoId}
                  id="player"
                  ref={vidRef}
                  opts={{
                    playerVars: {
                      autoplay: 1,
                      controls: 0,
                      rel: 0,
                      showinfo: 0,
                      mute: 1,
                      loop: 1,
                      playlist: videoId
                    }
                  }}
                />
                {/* <iframe
                  id="player"
                  frameBorder="0"
                  allowFullScreen
                  ref={vidRef}
                  tabIndex={"-1"}
                  src={`https://www.youtube.com/embed/${videoId}?autoplay=${
                    buttonTitle ? 1 : 0
                  }&controls=0&rel=0&showinfo=0&mute=1&loop=1&playlist=${videoId}`}
                /> */}
              </VideoForeground>
            </VideoBackground>
          )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  padding: 50px 0;
  height: ${(props) =>
    props.height === "full" ? `calc(100vh - ${headerHeight - 30}px)` : `calc(50vh - ${headerHeight - 30}px)`};
  min-height: 220px;
  display: flex;
  align-items: center;
  color: #fff;
  overflow: hidden;

  @media (min-width: ${menuBreakpoint}px) {
    height: ${(props) =>
      props.height === "full" ? `calc(100vh - ${headerHeight}px)` : `calc(50vh - ${headerHeight}px)`};
  }

  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);

    @media (min-width: 960px) {
      background: rgba(0, 0, 0, 0.2);
    }
  }
`
const TabButtonPause = styled.button`
  width: 100px;
  padding: 10px 20px;
  height: 50px;
  background: #32434e;
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
  margin-right: 1rem;
  position: absolute;
  top: 20px;
  left: 0;
  z-index: 10000;
  transition-duration: 500ms;
  transform: translateX(-100%);

  &:focus {
    position: absolute;
    top: 20px;
    left: 0;
    transform: translateX(0);
    transition-duration: 500ms;
    border-color: #32434e;
  }
`

const StyledEdges = styled(Edges)`
  display: flex;
  justify-content: ${(props) =>
    props.textAlign === `right` ? `flex-end` : props.textAlign === `left` ? `flex-start` : `center`};
`

const Content = styled.div`
  position: relative;
  z-index: 3;
  text-align: ${(props) => props.textAlign};

  @media (min-width: 1024px) {
    ${(props) => props.textAlign !== `center` && `max-width: 60%`};
  }
`

const VideoBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;

  @media (max-width: 600px) {
    display: none;
  }
`

const VideoForeground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  @media (min-aspect-ratio: 16/9) {
    height: 300%;
    top: -100%;
  }

  @media (max-aspect-ratio: 16/9) {
    width: 300%;
    left: -100%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
`

const decoration = css`
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 2px;
  background: #fff;
`

const TopLabel = styled(Typography)`
  && {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
    transform: ${(props) =>
      props.textAlign === `right`
        ? `translateX(-52px)`
        : props.textAlign === `left`
        ? `translateX(52px)`
        : `translateX(0)`};

    span {
      position: relative;

      &:before {
        ${decoration}
        left: -50px;
      }

      &:after {
        ${decoration}
        right: -50px;
      }
    }
  }
`

export default Hero
