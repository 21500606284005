const getParameter = parameterName => {
  let result = "",
    tmp = []
  if (typeof window !== `undefined`) {
    window.location.search
      .substr(1)
      .split("&")
      .forEach(function(item) {
        tmp = item.split("=")
        if (tmp[0] === parameterName) {
          result = decodeURIComponent(tmp[1])
        }
      })
  }
  return result
}

export default getParameter
