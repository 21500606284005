import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
// import Button from "./button/Button"
import isBrowser from "../utils/isBrowser"
import styled from "styled-components"
import * as theme from "theme"

const CookieBar = () => {
  const [showBanner, setShowBanner] = useState(isBrowser && window.localStorage.getItem("SHOW_BANNER"))

  const handleBanner = () => {
    window.localStorage.setItem("SHOW_BANNER", "false")
    setShowBanner(false)
  }
  useEffect(() => {
    setTimeout(() => {
      const data = window.localStorage.getItem("SHOW_BANNER")

      if (data !== null) setShowBanner(JSON.parse(data))
    }, 2000)
  }, [])
  useEffect(() => {
    if (showBanner === null) {
      window.localStorage.setItem("SHOW_BANNER", "true")
    }
  }, [showBanner])

  return (
    <>
      {showBanner && isBrowser && window.localStorage.getItem("SHOW_BANNER") === "true" && (
        <CookieBanner>
          <CookieBannerText>
            This website uses cookies. By continuing to use this website you are agreeing to our use of cookies as
            described in our <CookieLink to="/privacy">Privacy Policy.</CookieLink>
          </CookieBannerText>

          <CookieButton onClick={() => handleBanner(false)}>Accept</CookieButton>
        </CookieBanner>
      )}
    </>
  )
}

export default CookieBar

const CookieBanner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999999999999;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  background-color: #5c6368;
  padding-left: 70px;
  padding-right: 70px;
`

const CookieBannerText = styled.p`
  color: white;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
`

const CookieLink = styled(Link)`
  color: white;
  text-decoration: underline;
`

const CookieButton = styled.button`
  background-color: ${theme.colors.primary};
  cursor: pointer;
  border: none;
  color: white;
  padding: 5px 20px;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  margin-top: 11px;
  margin-bottom: 11px;
`
