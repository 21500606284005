import { createTheme } from "@material-ui/core/styles"
import colors from "./colors"

const defaultTheme = createTheme()
const { breakpoints } = defaultTheme

export default createTheme({
  shadows: ["none"],
  typography: {
    fontFamily: "Open Sans",
    h1: {
      fontFamily: "Poppins",
      textTransform: "uppercase",
      fontWeight: 700,
      fontSize: "50px",
      [breakpoints.up("sm")]: {
        fontSize: "60px"
      },
      [breakpoints.up("md")]: {
        fontSize: "75px"
      },
      [breakpoints.up("lg")]: {
        fontSize: "90px"
      }
    },
    h2: {
      fontFamily: "Poppins",
      textTransform: "uppercase",
      fontWeight: 700,
      fontSize: "40px",
      lineHeight: 1,
      [breakpoints.up("sm")]: {
        fontSize: "55px"
      },
      [breakpoints.up("md")]: {
        fontSize: "70px"
      },
      [breakpoints.up("lg")]: {
        fontSize: "80px"
      }
    },
    h3: {
      fontFamily: "Poppins",
      textTransform: "uppercase",
      fontWeight: 700,
      fontSize: "30px",
      lineHeight: 1,
      [breakpoints.up("sm")]: {
        fontSize: "40px"
      },
      [breakpoints.up("md")]: {
        fontSize: "50px"
      },
      [breakpoints.up("lg")]: {
        fontSize: "60px"
      }
    },
    h4: {
      fontFamily: "Poppins",
      textTransform: "uppercase",
      fontWeight: 700,
      fontSize: "24px",
      letterSpacing: "1px",
      [breakpoints.up("sm")]: {
        fontSize: "26px"
      },
      [breakpoints.up("md")]: {
        fontSize: "28px"
      },
      [breakpoints.up("lg")]: {
        fontSize: "32px"
      }
    },
    h5: {
      fontFamily: "Poppins",
      textTransform: "uppercase",
      fontSize: "22px",
      color: colors.primary,
      [breakpoints.up("sm")]: {
        fontSize: "24px"
      }
    },
    h6: { fontFamily: "Poppins", textTransform: "uppercase" },
    subtitle1: {
      fontFamily: "Poppins",
      textTransform: "uppercase",
      fontWeight: 700
    },
    subtitle2: {},
    body1: {},
    body2: {
      fontSize: 18
    },
    caption: {},
    button: {}
  },
  palette: {
    primary: {
      main: colors.primary,
      contrastText: "white"
    },
    secondary: {
      main: colors.secondary
    }
  },
  // https://material-ui.com/customization/themes/
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: "0.81rem"
      }
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        fontSize: "18px",
        padding: "4px 30px",
        border: `1px solid ${colors.primary}`
      },
      contained: {
        boxShadow: "none"
      },
      containedSecondary: {
        color: "#fff",
        border: `1px solid ${colors.secondary}`
      },
      outlined: {
        padding: "4px 30px"
      },
      outlinedSecondary: {
        color: "#fff",
        border: "1px solid #fff;",
        "&:hover": {
          backgroundColor: "#fff",
          color: colors.primary,
          border: "1px solid #fff;"
        }
      }
    }
  }
})
