import React, { useState } from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"

import { format } from "date-fns"

// import app components
import { Edges, Textarea, Spacer, BackgroundImage, Button } from "components"
import * as theme from "theme"

const Events = () => {
  const { nodes: events } = useEventQuery()

  const [category, setCategory] = useState(`all`)

  const availableLocations = []

  events.map((o) => {
    return (
      o?.locations?.nodes &&
      o.locations.nodes.map((p) => {
        if (!availableLocations.includes(p.name)) {
          return availableLocations.push(p.name)
        } else {
          return null
        }
      })
    )
  })

  const formatDate = (date) => {
    const newDate = date.slice(0, 4) + "/" + date.slice(4, 6) + "/" + date.slice(6)
    return newDate
  }

  let visibleEvents =
    category === `all`
      ? events
      : events.filter((o) => o?.locations?.nodes && o.locations.nodes.find((p) => p.name === category))

  const handleChangeCategory = (s) => setCategory(s)

  return (
    <Container>
      <Spacer pt={{ xs: 50 }} pb={{ xs: 50 }}>
        <Edges size="md">
          <Filter>
            <StyledButton
              onClick={() => handleChangeCategory(`all`)}
              children={`all`}
              variant={category === `all` ? `contained` : `outlined`}
              color="primary"
              small
            />

            {availableLocations.map((s) => (
              <StyledButton
                key={s}
                onClick={() => handleChangeCategory(s)}
                children={s}
                variant={category === s ? `contained` : `outlined`}
                color="primary"
                small
              />
            ))}
          </Filter>

          {visibleEvents &&
            visibleEvents.map((o) => {
              formatDate(o.acf.startDate)

              return (
                o?.language.code === process.env.GATSBY_ACTIVE_LANGUAGE && (
                  <Item key={o.id}>
                    <Content>
                      {o?.locations?.nodes &&
                        o.locations.nodes.map((p) => {
                          return <Tag key={p.databaseId} children={p.name} />
                        })}
                      <Spacer mb={5}>
                        <Typography variant="h4" children={o.title} />
                      </Spacer>

                      {o.acf.startDate && (
                        <Typography
                          children={`${format(new Date(formatDate(o.acf.startDate)), "MMM dd yyyy")} ${
                            o.acf.endDate && ` - ${format(new Date(formatDate(o.acf.endDate)), "MMM dd yyyy")}`
                          }`}
                          color="secondary"
                        />
                      )}

                      {o.acf.text && (
                        <Spacer mt={20}>
                          <Textarea content={o.acf.text} />
                        </Spacer>
                      )}

                      {o.acf.venue && (
                        <Spacer mt={20}>
                          <Typography variant="caption" children={`Venue`} />
                          <Typography children={o.acf.venue} />
                        </Spacer>
                      )}

                      {o.acf.website && (
                        <Spacer mt={20}>
                          <Typography variant="caption" children={`Website`} />
                          <Typography>
                            <a
                              href={o.acf.website}
                              target="_blank"
                              aria-label="Visit Website"
                              rel="noreferrer"
                              children={o.acf.website}
                            />
                          </Typography>
                        </Spacer>
                      )}
                    </Content>
                    <ImageContainer>
                      {o.acf.image && <BackgroundImage backgroundSize="contain" image={o.acf.image} />}
                    </ImageContainer>
                  </Item>
                )
              )
            })}
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled.div`
  background: ${theme.colors.background.dark};
`

const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
    justify-content: flex-start;
    margin-bottom: 40px;
  }
`

const StyledButton = styled(Button)`
  && {
    margin-right: 5px;
    margin-bottom: 5px;
  }
`

const Item = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 20px 30px;
  border: 1px solid ${theme.colors.text.light};
  margin-bottom: 20px;
  background: #fff;
`

const Content = styled.div`
  flex: 1;
`

const Tag = styled(Typography)`
  && {
    display: inline-block;
    padding: 2px 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 12px;
    text-transform: uppercase;
    background: ${theme.colors.secondary};
    color: #fff;
  }
`

const ImageContainer = styled.div`
  position: relative;
  width: 300px;
  height: 160px;
`

const useEventQuery = () => {
  const { allWpEvent } = useStaticQuery(
    graphql`
      {
        allWpEvent(sort: { fields: acf___startDate }) {
          nodes {
            language {
              code
            }
            id
            title
            uri
            date
            locations {
              nodes {
                name
                databaseId
              }
            }
            acf {
              text
              startDate
              endDate
              website
              venue
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 600, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allWpEvent
}

export default Events
