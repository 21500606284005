import React from "react"
import styled from "styled-components"
import { IconButton } from "@material-ui/core"
import { graphql, navigate, useStaticQuery, Link } from "gatsby"

// import app components
import FacebookIcon from "icons/facebook.svg"
import TwitterIcon from "icons/twitter.svg"
import InstagramIcon from "icons/instagram.svg"
import VimeoIcon from "icons/vimeo.svg"
import YoutubeIcon from "icons/youtube.svg"
import * as theme from "theme"

const Socials = (props) => {
  const { size, ...rest } = props

  const {
    themeOptions: {
      siteOptions: {
        socialMedia: {
          facebook,
          instagram,
          twitter,
          youtube,
          vimeo,
          auFacebook,
          auInstagram,
          auVimeo,
          auTwitter,
          auYoutube
        }
      }
    }
  } = useOptionsQuery()
  const checkRegion = process.env.GATSBY_ACTIVE_LANGUAGE === "AU"

  return (
    <Container {...rest}>
      {!checkRegion && facebook && (
        <LinkContainer size={size} href={facebook} target="_blank" rel="noreferrer nofollow" aria-label="Facebook">
          <FacebookIcon />
        </LinkContainer>
      )}
      {!checkRegion && instagram && (
        <LinkContainer size={size} to={instagram} target="_blank" aria-label="Instagram" title="Link to Facebook">
          <InstagramIcon />
        </LinkContainer>
      )}
      {!checkRegion && twitter && (
        <LinkContainer size={size} to={twitter} target="_blank" rel="noreferrer nofollow" aria-label="Twitter">
          <TwitterIcon />
        </LinkContainer>
      )}
      {!checkRegion && vimeo && (
        <LinkContainer size={size} to={vimeo} target="_blank" rel="noreferrer nofollow" aria-label="Vimeo">
          <VimeoIcon />
        </LinkContainer>
      )}
      {!checkRegion && youtube && (
        <LinkContainer size={size} to={youtube} target="_blank" rel="noreferrer nofollow" aria-label="Youtube">
          <YoutubeIcon />
        </LinkContainer>
      )}
      {checkRegion && auFacebook && (
        <LinkContainer
          size={size}
          to={auFacebook}
          target="_blank"
          rel="noreferrer nofollow"
          aria-label="Link to Facebook"
        >
          <FacebookIcon />
        </LinkContainer>
      )}
      {checkRegion && auInstagram && (
        <LinkContainer
          size={size}
          to={auInstagram}
          title="Link to Instagram"
          aria-label="Link to Instagram"
          rel="noreferrer nofollow"
        >
          <InstagramIcon />
        </LinkContainer>
      )}
      {checkRegion && auTwitter && (
        <LinkContainer size={size} to={auTwitter} target="_blank" rel="noreferrer nofollow" aria-label="Twitter">
          <TwitterIcon />
        </LinkContainer>
      )}
      {checkRegion && auVimeo && (
        <LinkContainer size={size} to={auVimeo} target="_blank" rel="noreferrer nofollow" aria-label="Vimeo">
          <VimeoIcon />
        </LinkContainer>
      )}
      {checkRegion && auYoutube && (
        <LinkContainer
          size={size}
          to={auYoutube}
          target="_blank"
          rel="noreferrer nofollow"
          aria-label="Link to Youtube"
        >
          <YoutubeIcon />
          {/* <SocialText>Link to Youtube</SocialText> */}
        </LinkContainer>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
`

// const Link = styled.a`
//   text-decoration: none;
//   display: inline-block;
//   margin: 0 10px;

//   @media (min-width: 768px) {
//     margin: 0 4px;
//   }
// `

const SocialText = styled.p`
  color: #fff;
  font-size: 8px;
`

const LinkContainer = styled(Link)`
  width: ${(props) => (props.size === "lg" ? "40px" : "24px")};
  height: ${(props) => (props.size === "lg" ? "40px" : "24px")};
  background: #fff;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: ${(props) => (props.size === "lg" ? "10px" : "5px")};
  &:hover {
    background: rgba(255, 255, 255, 0.8);
  }

  .MuiIconButton-label {
    width: ${(props) => (props.size === "lg" ? "40px" : "24px")};
  }

  svg {
    width: ${(props) => (props.size === "lg" ? "25px" : "18px")} !important;
    height: ${(props) => (props.size === "lg" ? "25px" : "18px")} !important;

    path {
      fill: ${theme.colors.primary};
    }
  }
`

const StyledIconButton = styled(({ size, ...rest }) => <IconButton {...rest} />)`
  width: ${(props) => (props.size === "lg" ? "40px" : "24px")};
  height: ${(props) => (props.size === "lg" ? "40px" : "24px")};
  background: #fff;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: rgba(255, 255, 255, 0.8);
  }

  .MuiIconButton-label {
    width: ${(props) => (props.size === "lg" ? "40px" : "24px")};
  }

  svg {
    width: ${(props) => (props.size === "lg" ? "25px" : "18px")} !important;
    height: ${(props) => (props.size === "lg" ? "25px" : "18px")} !important;

    path {
      fill: ${theme.colors.primary};
    }
  }
`

const useOptionsQuery = () => {
  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          themeOptions {
            siteOptions {
              socialMedia {
                facebook
                instagram
                twitter
                vimeo
                youtube
                auFacebook
                auInstagram
                auVimeo
                auTwitter
                auYoutube
              }
            }
          }
        }
      }
    `
  )
  return wp
}

export default Socials
