import React, { useEffect } from "react"

/**
 * Loads images into mapbox once on first load.
 */
const useMapImageLoading = (
  /**
   * Ref of the mapbox
   */
  _mapRef: React.MutableRefObject<any>,

  items?: Array<{
    /**
     * Absolute path to image. SVG files not supported.
     */
    path: string

    /**
     * Name of image to be loaded into the mapbox style.
     */
    name: string
  }>
) => {
  useEffect(() => {
    const map = _mapRef.current.getMap()

    items &&
      items.forEach(({ path, name }) => {
        map.loadImage(path, (error, image) => {
          if (error) throw error

          if (!map.hasImage(name)) map.addImage(name, image, { sdf: true })
        })
      })
  }, [_mapRef])
}

export default useMapImageLoading
