import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Parser from "html-react-parser"
import { Typography } from "@material-ui/core"

// import app components
import useMenuItems from "./useMenuItems"
import { getUrlPath } from "utils"
import * as theme from "theme"

const FooterMenu = (props) => {
  const { name, headline, ...rest } = props

  const items = useMenuItems(name)

  return (
    <Container>
      {headline && <Headline variant="h4" color="inherit" children={headline} />}

      <Menu {...rest}>
        {items &&
          items.map(({ id, url, label }) => (
            <MenuItem key={id}>
              {url.includes("http") ? (
                <ExternalMenuLink href={url} target="_blank">
                  <div color="inherit" children={Parser(label)} />
                </ExternalMenuLink>
              ) : (
                <MenuLink to={getUrlPath(url)}>
                  <div color="inherit" children={Parser(label)} />
                </MenuLink>
              )}
            </MenuItem>
          ))}
      </Menu>
    </Container>
  )
}

export default FooterMenu

const Container = styled.div`
  width: 220px;
  margin-right: 40px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
    margin-right: 100px;
  }
`

const Headline = styled(Typography)`
  && {
    position: relative;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 25px;
    &:after {
      content: "";
      position: absolute;
      bottom: -15px;
      left: 0;
      width: 100%;
      height: 2px;
      background: #fff;
    }
  }
`

const Menu = styled.div`
  display: flex;
  flex-wrap: wrap;

  a {
    text-decoration: none;
  }
`

const MenuItem = styled.div`
  display: flex;
  width: 100%;
  margin: 5px 0;
`

const MenuLink = styled(Link)`
  color: #fff;
  font-size: 18px;
  font-family: "Poppins";
  text-transform: uppercase;
`

const ExternalMenuLink = styled.a`
  color: #fff;
  font-size: 18px;
  font-family: "Poppins";
  text-transform: uppercase;
`
