import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import * as theme from "../theme"
import { useStore } from "store"

const SkipTabLink = (props) => {
  const { path } = props
  const ref = useRef(null)

  const [{ appState }, dispatch] = useStore()

  useEffect(() => {
    ref.current?.focus()
    dispatch({ type: "SKIP_TAB_HEADER", payload: false })
  }, [path, dispatch])

  return (
    <>
      <span ref={ref} tabIndex={-1} />
      <SkipTabLinkContainer
        className="skip-link"
        onClick={() => {
          dispatch({ type: "SKIP_TAB_HEADER", payload: true })
        }}
        href="#main-content"
      >
        Skip Navigation Links
      </SkipTabLinkContainer>
      <span />
    </>
  )
}
export default SkipTabLink

const SkipTabLinkContainer = styled.a`
  width: fit-content;
  padding: 10px 20px;
  height: fit-content;
  background: ${theme.colors.primary};
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
  margin-right: 1rem;
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 10000;
  transition-duration: 500ms;
  transform: translateX(-100%);

  &:focus {
    position: absolute;
    top: 0px;
    left: 0;
    transform: translateX(0);
    transition-duration: 500ms;
    border-color: ${theme.colors.primary};
  }
`
