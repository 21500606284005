import produce from "immer"

export const appState = {
  menu: false,
  skipTabHeader: false,
  videoPlayer: {
    video: "",
    visible: false
  },
  lightBox: {
    slide: 0,
    open: false,
    slides: [],
    options: {
      thumbs: false
    }
  }
}

export const appReducer = (state, action) => {
  const { payload } = action

  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_MENU":
        draft.menu = payload
        break

      case "TOGGLE_MENU":
        draft.menu = !draft.menu
        break

      case "SKIP_TAB_HEADER":
        draft.skipTabHeader = payload
        break

      case "START_VIDEO_PLAYER":
        draft.videoPlayer = { video: payload, visible: true }
        break

      case "STOP_VIDEO_PLAYER":
        draft.videoPlayer = { video: "", visible: false }
        break

      case "SET_LIGHTBOX":
        draft.lightBox = { ...draft.lightBox, ...payload }
        break

      case "CLOSE_LIGHTBOX":
        draft.lightBox = {
          slide: 0,
          open: false,
          slides: [],
          options: {
            thumbs: false
          }
        }
        break

      default:
    }
  })
}
