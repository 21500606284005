import React, {  useRef, useState } from "react"
import styled, { css } from "styled-components"

import BackgroundImage from "../backgroundImage"

export default function BackgroundVideo(props) {
  const { src, autoPlay, loop, muted, children, overlay, contentStyle, center, placeholder, controls, ...rest } = props
  const vidRef = useRef(null)
  const [buttonTitle, setButtonTitle] = useState("Pause")

  const handlePlay = () => {
    vidRef.current.play()
    setButtonTitle("Pause")
  }
  const handlePause = () => {
    vidRef.current.pause()
    setButtonTitle("Play")
  }

  const handleToggleVideo = () => (vidRef.current.paused ? handlePlay() : handlePause())

  return (
    <Container {...rest}>
      {!!src && (
        <>
          <TabButtonPause onClick={handleToggleVideo}>{`${buttonTitle}`}</TabButtonPause>
          <Video id="background-video" autoPlay={autoPlay} loop={loop} muted={muted} controls={controls} ref={vidRef}>
            <source src={src} type="video/mp4" />
            <source src={src} type="video/ogg" />
            Your browser does not support the video tag.
          </Video>
        </>
      )}
      {!!placeholder && <PlaceholderImage position="absolute" bg={placeholder.url ? placeholder.url : placeholder} />}
      {children && (
        <Content style={contentStyle} center={center} overlay={overlay}>
          {children}
        </Content>
      )}
    </Container>
  )
}

BackgroundVideo.defaultProps = {
  loop: true,
  autoPlay: true,
  muted: true,
  overlay: 0.2,
  center: true,
  height: "100%"
}

const fullSize = css`
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`
const TabButtonPause = styled.button`
  width: 100px;
  padding: 10px 20px;
  height: 50px;
  background: #32434e;
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
  margin-right: 1rem;
  position: absolute;
  top: 20px;
  left: 0;
  z-index: 10000;
  transition-duration: 500ms;
  transform: translateX(-100%);

  &:focus {
    position: absolute;
    top: 20px;
    left: 0;
    transform: translateX(0);
    transition-duration: 500ms;
    border-color: #32434e;
  }
`

const Container = styled.div`
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
  position: relative;
  width: 100%;
`
const Video = styled.video`
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 1;
`
const Content = styled.div`
  ${(props) =>
    !!props.overlay &&
    css`
      &::after {
        content: "";
        position: absolute;
        ${fullSize}
        background: rgba(0,0,0, ${props.overlay});
        z-index: -1;
      }
    `}
  ${(props) =>
    props.center &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
    `}
  position: absolute;
  ${fullSize}
  height: 100%;
  z-index: 1;
  padding: 30px;
  color: white;
`

const PlaceholderImage = styled(BackgroundImage)`
  z-index: 0;
`
