const colors = {
  primary: "#32434E",
  secondary: "#37ab9c",
  background: {
    light: "#fff",
    dark: "#f7f7f7"
  },
  text: {
    light: "#F6F7F7",
    dark: "#232323"
  },
  success: "#00A497",
  error: "#e24141"
}

export default colors
