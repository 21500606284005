import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import { Typography } from "@material-ui/core"

// import app components
import Button from "../button"
import { Spacer, Edges, BackgroundImage } from "components"
import Textarea from "components/textarea"
import Headline from "./Headline"

import * as theme from "theme"

export default function Testimonials(props) {
  const { headline, image, items, id, ...rest } = props

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <>
      {headline && <Headline id={id} headline={headline} />}
      <Container {...rest}>
        <ImageContainer>{image && <BackgroundImage backgroundSize={"cover"} image={image} />}</ImageContainer>

        <Edges size="md">
          <Content>
            <Spacer pl={{ md: 40 }} pt={{ xs: 40, md: 80 }} pb={{ xs: 60, md: 120 }}>
              <Slider {...settings}>
                {items &&
                  items.map((o, i) => {
                    return (
                      <Item key={i}>
                        {o.image && (
                          <ItemImageContainer>
                            <BackgroundImage image={o.image} imageSize="cover" />
                          </ItemImageContainer>
                        )}

                        {o.name && (
                          <Spacer mb={30}>
                            <Typography children={o.name} variant="h6" color="secondary" />
                          </Spacer>
                        )}
                        {o.text && <StyledTextarea content={o.text} />}

                        {o.button && <Button to={o.button.url}>{o.button.title}</Button>}
                      </Item>
                    )
                  })}
              </Slider>
            </Spacer>
          </Content>
        </Edges>
      </Container>
    </>
  )
}

const Container = styled.div`
  position: relative;

  .slick-dots {
    bottom: -50px;
  }
`

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  order: 1;
  border-bottom: 1px solid ${theme.colors.primary};

  @media (min-width: 960px) {
    position: absolute;
    top: 0;
    width: 40%;
    height: 100%;
    left: 0;
    border-bottom: none;
  }
`

const Content = styled.div`
  width: 100%;
  position: relative;
  z-index: 5;

  @media (min-width: 960px) {
    width: 60%;
    margin-left: 40%;
  }
`

const Item = styled.div`
  padding: 0 20px;
`

const ItemImageContainer = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 2px solid ${theme.colors.primary};
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 30px;
`

const StyledTextarea = styled(Textarea)`
  font-style: italic;
  margin-bottom: 30px;
`
