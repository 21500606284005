import React from "react"
import styled from "styled-components"
import { QueryClient, QueryClientProvider } from "react-query"
// import app components
import { Header, Footer, LightBox } from "components"
import SkipTabLink from "../SkipTabLink"
import CookieBar from "../CookieBar.js"

export default (props) => {
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <Container>
        <SkipTabLink path={props?.path} />
        <Header />
        <Main id="main-content">{props?.children}</Main>
        <Footer />
        <LightBox />
        <CookieBar />
      </Container>
    </QueryClientProvider>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`

const Main = styled.main``
