import React from "react"
import { Link, navigate } from "gatsby"
import styled, { css } from "styled-components"
import Parser from "html-react-parser"
import { Button as MuiButton, CircularProgress } from "@material-ui/core"

export default ({ children, to, loading, disabled, color, variant, ...rest }) => {
  const fixedUrl = to && to.replace(process.env.GATSBY_WP, "")

  const element = (
    <Button {...rest} color={color} variant={variant} disabled={disabled} loading={loading ? 1 : 0}>
      <CircularProgress className="btn-loader" size={25} title={children} aria-label={children} />
      <span className="btn-children">{Parser(children)}</span>
    </Button>
  )

  if (to) {
    if (disabled) {
      return element
    } else if (fixedUrl.includes("http")) {
      return (
        <Button
          href={fixedUrl}
          target="_blank"
          rel="noreferrer"
          title={children}
          aria-label={children}
          color={color}
          variant={variant}
          disabled={disabled}
          loading={loading ? 1 : 0}
        >
          <CircularProgress className="btn-loader" size={25} title={children} aria-label={children} />
          <span className="btn-children">{Parser(children)}</span>
        </Button>
      )
    } else if (fixedUrl.includes("tel:") || fixedUrl.includes("mailto:")) {
      return (
        <Button
          href={fixedUrl}
          title={children}
          aria-label={children}
          color={color}
          variant={variant}
          disabled={disabled}
          loading={loading ? 1 : 0}
        >
          <CircularProgress className="btn-loader" size={25} title={children} aria-label={children} />
          <span className="btn-children">{Parser(children)}</span>
        </Button>
      )
    } else {
      return (
        <Button
          onClick={() => {
            navigate(fixedUrl)
          }}
          title={children}
          aria-label={children}
          color={color}
          variant={variant}
          disabled={disabled}
          loading={loading ? 1 : 0}
        >
          <CircularProgress className="btn-loader" size={25} title={children} aria-label={children} />
          <span className="btn-children">{Parser(children)}</span>
        </Button>
      )
    }
  } else {
    return element
  }
}

const Button = styled(({ small, loading, ...rest }) => <MuiButton {...rest} />)`
  position: relative;

  ${(props) =>
    props.small &&
    `
      padding: 5px 20px;
      font-size: 16px;
  `}

  .btn-loader {
    position: absolute;
    opacity: 0;
    z-index: 1;
    pointer-events: none;
    left: calc(50% - 12.5px);
  }

  ${({ loading }) =>
    loading &&
    css`
      .btn-children {
        opacity: 0;
      }
      .btn-loader {
        opacity: 1;
      }
    `}
`
