import React from "react"

// import app components
import FormContainer from "components/form"
import { Edges, Spacer } from "components"
import getParameter from "../../utils/getParameter"

const Form = props => {
  return (
    <Spacer pt={{ xs: 40, sm: 50 }} pb={{ xs: 40, sm: 50 }}>
      <Edges size="sm">
        {props?.getParameter && getParameter(props.getParameter) ? (
          <FormContainer
            {...props}
            formId={props.newFormId}
            hiddenFields={[{ id: 14, content: getParameter(props.getParameter) }]}
            hideTitle
          />
        ) : (
          <FormContainer {...props} hideTitle />
        )}
      </Edges>
    </Spacer>
  )
}

export default Form
