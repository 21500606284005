import React, { useRef, useState } from "react"

import ReactMapGL, { Source, Layer, NavigationControl, Popup, FlyToInterpolator, GeolocateControl } from "react-map-gl"

import "maplibre-gl/dist/maplibre-gl.css"

import useMapImageLoading from "./useMapImageLoading"
import { set } from "lodash"

interface MapProps {
  geolocation?: any
  setUserLat?: any
  setUserLng?: any
  setFilter?: any
  searchedLocation?: any

  /**
   * The Mapbox style. A string url.
   */
  mapStyle?: string

  /**
   *
   */
  geojson?: any
  dragPan?: boolean

  /**
   * Initial viewport coordinates and zoom level
   */
  defaultViewport?: {
    latitude?: number
    longitude?: number
    zoom?: number
  }

  /**
   * Function triggered when the map is clicked
   */
  onClick?: (
    /**
     * The mapbox click event
     */
    args?: {
      event: any
      properties?: {
        [x: string]: any
      }

      isCluster?: boolean
      clusterId?: number
    }
  ) => void
}

const Map: React.FC<MapProps> = ({
  geojson,
  defaultViewport,
  onClick,
  mapStyle,
  setUserLat,
  setUserLng,
  setFilter,
  searchedLocation,
  dragPan,
  onSearchStart,
  smallMap,
  onSearchEnd
}) => {
  const initialViewport = {
    ...defaultViewport,
    transitionDuration: 0
  }

  const [viewport, setViewport] = useState(initialViewport)

  const mapRef = useRef(null)

  const [popup, setPopup] = useState({
    latitude: 0,
    longitude: 0,
    description: null
  })
  const [showPopup, setShowPopup] = useState(false)

  React.useEffect(() => {
    if (searchedLocation?.bbox) {
      const [south, west, north, east] = searchedLocation.bbox
      const map = mapRef?.current?.getMap()

      onSearchStart?.(searchedLocation)
      map.fitBounds(
        [
          [south, west],
          [north, east]
        ],
        { zoom: 8 }
      )

      setTimeout(() => {
        const zoom = map?.getZoom()

        const center = map?.getCenter()

        const { lng, lat } = center
        onSearchEnd?.(searchedLocation)
        setViewport({
          ...viewport,
          longitude: lng,
          latitude: lat,
          zoom,
          transitionDuration: 0
        })
      }, 3000)
    } else if (searchedLocation?.center) {
      const [longitude, latitude] = searchedLocation.center
      flyTo({
        longitude,
        latitude,
        zoom: 8
      })
    }
  }, [searchedLocation])
  /**
   * Show the popup when the mouse enters a marker
   */
  const handleMouseEnter = (event) => {
    const feature = event?.features?.[0]

    if (feature?.properties?.cluster) {
      setPopup({
        latitude: feature?.properties?.latitude,
        longitude: feature?.properties?.longitude,
        description: "Click to zoom"
      })
      setShowPopup(true)
    } else if (feature) {
      setPopup({
        longitude: feature?.geometry?.coordinates[0],
        latitude: feature?.geometry?.coordinates[1],
        description: feature?.properties?.description
      })

      setShowPopup(true)
    }
  }

  /**
   * Hides the popup when the mouse leaves the marker
   */
  const handleMouseLeave = () => {
    setShowPopup(false)
  }

  const updateSellerLocation = (event) => {
    const clickedLng = event?.longitude
    const clickedLat = event?.latitude
    setUserLng(clickedLng)
    setUserLat(clickedLat)
    setFilter("All")
  }

  /**
   * Zooms in on marker clusters or scrolls to single marker location in the sidebar
   */
  const handleClick = (event) => {
    const clickedLng = event?.lngLat[0]
    const clickedLat = event?.lngLat[1]
    const feature = event?.features?.[0]
    const isCluster = !!event?.features[0]?.properties?.cluster
    const clusterId = feature?.properties?.cluster_id

    if (isCluster && clusterId) {
      setShowPopup(false)
      // get cluster expansion zoom
      mapRef?.current
        .getMap?.()
        .getSource?.("geojson-data")
        .getClusterExpansionZoom?.(clusterId, (err, zoom) => {
          if (err) {
            return
          }

          flyTo({
            longitude: feature?.geometry?.coordinates[0],
            latitude: feature?.geometry?.coordinates[1],
            zoom: zoom + 1
          })
        })
    } else if (feature) {
      // handle single marker

      flyTo({
        longitude: feature?.geometry?.coordinates[0],
        latitude: feature?.geometry?.coordinates[1],
        zoom: 4
      })
    }

    onClick?.({ event, properties: feature?.properties, isCluster, clusterId })
  }

  const flyTo = ({ latitude, longitude, zoom = 6 }: { latitude: number; longitude: number; zoom?: number }) => {
    setViewport({
      ...viewport,
      longitude,
      latitude,
      zoom,
      transitionDuration: 500,
      transitionInterpolator: new FlyToInterpolator()
    })
  }

  /**
   * Loads the marker image files into the mapbox style on first load.
   */
  useMapImageLoading(mapRef, [
    {
      path: "/marker.png", // absolute path to marker image. This one exists at /static/marker.png
      name: "map-pin"
    },
    {
      path: "/markerCluster.png",
      name: "map-pin-cluster"
    }
  ])

  return (
    <ReactMapGL
      ref={mapRef}
      dragPan={dragPan}
      mapboxApiAccessToken={process.env.GATSBY_PUBLIC_MAPBOX}
      {...viewport}
      width="100%"
      scrollZoom={true}
      height="100%"
      onViewportChange={(viewport) => {
        setViewport(viewport), updateSellerLocation(viewport)
      }}
      interactiveLayerIds={["markers"]}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      mapStyle={mapStyle}
    >
      <NavigationControl
        style={{
          right: 10,
          top: 10
        }}
        showCompass={false}
      />

      {!smallMap && (
        <GeolocateControl
          style={{
            left: 10,
            top: 10
          }}
          fitBoundsOptions={{ maxZoom: 6 }}
          auto={true}
        />
      )}

      {showPopup && popup?.latitude && popup?.longitude && (
        <Popup
          latitude={popup.latitude}
          longitude={popup.longitude}
          closeButton={false}
          closeOnClick={false}
          anchor="bottom"
          offsetTop={-18}
        >
          <span>{popup?.description}</span>
        </Popup>
      )}

      {geojson && (
        <Source id="geojson-data" type="geojson" data={geojson} clusterRadius={30} cluster={true}>
          <Layer
            id="markers"
            type="symbol"
            layout={{
              "icon-allow-overlap": true,
              "icon-image": [
                "case", // use map-pin-cluster image if the feature is a cluster
                ["has", "cluster"],
                "map-pin-cluster",

                "map-pin" // use default map-pin image if not a cluster
              ],

              "icon-size": 0.2
            }}
            paint={{ "icon-color": "#37AB9C" }} //required prop for some reason
          />
        </Source>
      )}
    </ReactMapGL>
  )
}

export default Map
