import React, { useState, useEffect } from "react"
import styled, { keyframes } from "styled-components"
import { Typography, Dialog } from "@material-ui/core"
// import handleClick from "../../components/map/Map"
// import Geocoder from "react-mapbox-gl-geocoder"
import Parser from "html-react-parser"
import AddressIcon from "@material-ui/icons/PinDrop"
import PhoneIcon from "@material-ui/icons/Phone"
import WebsiteIcon from "@material-ui/icons/Link"
import Map from "../../components/map/Map"
// import app components
import MarkerIcon from "icons/marker.svg"
// import LocateIcon from "icons/locate.svg"
import { useQuery, useMutation } from "react-query"
import { Spacer, Button } from "components"

import * as theme from "theme"
import { headerHeight, menuBreakpoint } from "components/header/Header"
import { useForm } from "react-hook-form"

const DealerLocator = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const [userLat, setUserLat] = useState(null)
  const [userLng, setUserLng] = useState(null)

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setUserLat(position.coords.latitude)
      setUserLng(position.coords.longitude)
    })
  }, [])

  let defaultLocation = { latitude: 0, longitude: 0 }

  const [location, setLocation] = useState({ ...defaultLocation })
  const [dialog, setDialog] = useState(null)
  const [isDisabled, setIsDisabled] = useState(true)
  const [filter, setFilter] = useState("All")
  // async useQuery call to get the dealer data
  useEffect(() => {
    setTimeout(() => {
      setIsDisabled(false)
    }, 2000)
  }, [])

  const { isLoading, error, data, isSuccess } = useQuery({
    queryKey: ["dealerData"],
    queryFn: () => fetch("https://www.highfieldboats.com/wp-json/wp/v2/get_dealers").then((res) => res.json())
  })

  const searchMutation = useMutation(async (variables) => {
    const dealerData = JSON.parse(data || null)

    // find the dealerData that matches the search query by title and return the address of it
    const newVariable = dealerData?.find((dealer) => {
      return dealer?.title?.toLowerCase().includes(variables.search?.toLowerCase())
    })

    const res = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${
        newVariable?.acf?.address || variables?.search
      }.json?limit=10?fuzzyMatch=false&access_token=${process.env.GATSBY_PUBLIC_MAPBOX}`
    )

    // filter the response to only show the search results from the dealerData query by place_name

    const body = await res.json()

    return body
  })

  const dealerData = JSON.parse(data || null)

  const filteredDealers =
    dealerData &&
    dealerData.filter((dealer) => {
      if (!filter?.[1] || !filter?.[0]) return false
      const lat1 = dealer?.acf?.lat
      const lon1 = dealer?.acf?.lng
      const lat2 = filter?.[1]
      const lon2 = filter?.[0]

      const R = 6371e3 // metres
      const φ1 = (lat1 * Math.PI) / 180 // φ, λ in radians
      const φ2 = (lat2 * Math.PI) / 180
      const Δφ = ((lat2 - lat1) * Math.PI) / 180
      const Δλ = ((lon2 - lon1) * Math.PI) / 180

      const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

      const d = R * c // in metres

      return d < 50000
    })

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371 // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1) // deg2rad below
    const dLon = deg2rad(lon2 - lon1)
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const d = R * c // Distance in km
    return d
  }

  const deg2rad = (deg) => {
    return deg * (Math.PI / 180)
  }

  const geojsonSingle = {
    type: "FeatureCollection",
    features: [
      {
        id: 0,

        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [dialog?.acf?.lng, dialog?.acf?.lat]
        },
        properties: {
          id: dialog?.id,
          description: dialog?.title,
          phone: dialog?.acf?.phone_number,
          address: dialog?.acf?.location?.address,
          longitude: parseInt(dialog?.acf?.lng),
          latitude: parseInt(dialog?.acf?.lat)
          // ...dealer?.acf
        }
      }
    ]
  }

  const geojson = {
    type: "FeatureCollection",
    features: dealerData?.map((dealer, index) => {
      return {
        id: index,
        type: "Feature",

        geometry: {
          type: "Point",
          coordinates: [dealer?.acf?.lng, dealer?.acf?.lat]
        },
        properties: {
          id: dealer?.id,
          description: dealer?.title,
          phone: dealer?.acf?.phone_number,
          address: dealer?.acf?.location?.address,
          longitude: parseInt(dealer?.acf?.lng),
          latitude: parseInt(dealer?.acf?.lat)
          // ...dealer?.acf
        }
      }
    })
  }

  const onMarkerClick = ({ event, properties, isCluster }) => {
    if (event?.features?.[0] && !isCluster) {
      const clickedDealer = dealerData?.find?.((dealer) => dealer.id === properties.id)

      setDialog(clickedDealer)
    }
  }

  return (
    <>
      <Container>
        <MapContainer>
          {isSuccess && (
            <Map
              mapStyle="mapbox://styles/mapbox/streets-v8"
              onClick={onMarkerClick}
              MarkerIcon={MarkerIcon}
              searchedLocation={searchMutation?.data?.features?.[0]}
              geojson={geojson}
              dragPan={!isDisabled}
              onSearchEnd={() => {
                setIsDisabled(false)
              }}
              setUserLat={setUserLat}
              setUserLng={setUserLng}
              onSearchStart={() => {
                setIsDisabled(true)
                setFilter(searchMutation?.data?.features?.[0]?.center)
              }}
              setFilter={setFilter}
            />
          )}
        </MapContainer>

        <Sidebar>
          <GeoLookupContainer component="div">
            <GeocoderContainer>
              <form
                onSubmit={handleSubmit((data) => {
                  searchMutation.mutate(data)
                })}
              >
                <StyledInput {...register("search")} disabled={isDisabled} placeholder={"Enter an Address..."} />
              </form>
            </GeocoderContainer>
          </GeoLookupContainer>

          {/* by default show all dealer locations but when search is active and no results appear don't show any*/}
          {
            <ResetButton
              children={"Reset Filter"}
              color="secondary"
              onClick={() => {
                setFilter("All")
                setLocation({ ...defaultLocation })
              }}
              disabled={isDisabled}
            />
          }
          {filter === "All"
            ? dealerData
                ?.sort(
                  (a, b) =>
                    calculateDistance(userLat, userLng, a?.acf?.lat, a?.acf?.lng) -
                    calculateDistance(userLat, userLng, b?.acf?.lat, b?.acf?.lng)
                )
                .map((o) => {
                  return (
                    <SidebarItem className={`dealer-location-select`} key={o.id} onClick={() => setDialog(o)}>
                      <SidebarTitle
                        className={`dealer-location-select`}
                        children={Parser(o.title)}
                        variant="h5"
                        color="secondary"
                      />
                      {o?.acf?.address && (
                        <Typography
                          children={Parser(o.acf.address)}
                          className={`dealer-location-select`}
                          variant="caption"
                          color="primary"
                        />
                      )}
                    </SidebarItem>
                  )
                })
            : filteredDealers?.map((o) => {
                if (!o?.acf?.address) {
                  return null
                }
                return (
                  <SidebarItem className={`dealer-location-select`} key={o.id} onClick={() => setDialog(o)}>
                    <SidebarTitle
                      className={`dealer-location-select`}
                      children={Parser(o.title)}
                      variant="h5"
                      color="secondary"
                    />
                    {o?.acf?.address && (
                      <Typography
                        children={Parser(o.acf.address)}
                        className={`dealer-location-select`}
                        variant="caption"
                        color="primary"
                      />
                    )}
                  </SidebarItem>
                )
              })}
        </Sidebar>
      </Container>

      <Dialog open={!!dialog} onClose={() => setDialog(null)} maxWidth="md" fullWidth>
        {!!dialog && (
          <Flex id="dealer-modal">
            <SmallMapContainer>
              <Map
                defaultViewport={{ longitude: parseInt(dialog?.acf?.lng), latitude: parseInt(dialog?.acf?.lat) }}
                // eslint-disable-next-line
                smallMap={true}
                geojson={geojsonSingle}
                mapStyle="mapbox://styles/mapbox/streets-v8"
              ></Map>
            </SmallMapContainer>

            <Spacer p={30}>
              <div>
                <Spacer mb={10}>
                  <Typography variant="h5" color="secondary" children={Parser(dialog.title)} />

                  {dialog.content && (
                    <Content>
                      <Typography variant="caption" color="primary" children={Parser(dialog.content)} />
                    </Content>
                  )}
                </Spacer>

                {dialog.acf.address && (
                  <DialogItem>
                    <AddressIcon />
                    <Typography variant="caption" color="primary" children={Parser(dialog.acf.address)} />
                  </DialogItem>
                )}
                {dialog.acf.phone_number && (
                  <DialogItem>
                    <PhoneIcon />
                    <Typography variant="caption" color="primary" children={Parser(dialog.acf.phone_number)} />
                  </DialogItem>
                )}

                {dialog.acf.link && (
                  <DialogItem>
                    <a href={dialog.acf.link} target="_blank" rel="noopener noreferrer">
                      <WebsiteIcon />
                      <Typography variant="caption" color="primary" children={Parser(dialog.acf.link)} />
                    </a>
                  </DialogItem>
                )}
              </div>

              <Spacer mt={20}>
                <Button variant="contained" color="secondary" children={`Request a Quote`} to={`/request-a-quote`} />
              </Spacer>
            </Spacer>
          </Flex>
        )}
      </Dialog>
    </>
  )
}

const ResetButton = styled(Button)`
  width: 100%;
  font-size: 13px;
  margin-bottom: 20px;
`

const smoothBounce = keyframes`
    0%   { transform: translateY(0); }
    50%  { transform: translateY(-10px); }
    100% { transform: translateY(0); }
`

const StyledButton = styled(Button)`
  width: 100%;
  font-size: 13px;
`

const StyledInput = styled.input`
  color: #ffffff !important;
`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 20px 0px;

  .mapboxgl-popup {
    max-width: 250px;
  }

  .marker-container {
    display: flex;
    justify-content: center;

    &:hover {
      .marker-tooltip {
        display: block;
      }
    }
  }

  .marker-bounce {
    > svg {
      animation: ${smoothBounce} 2s ease infinite;
    }
    > .marker-tooltip {
      display: block;
      animation: ${smoothBounce} 2s ease infinite;
    }
  }
`

const GeoLookupContainer = styled(Typography)`
  position: relative;
  display: flex;
  align-items: center;
  background: ${theme.colors.secondary};
  height: 60px;
`

const GeocoderContainer = styled.div`
  width: 100%;

  input {
    height: 36px;
    width: 100%;

    position: relative;
    border: none;
    background: transparent;
    padding: 12px 12px 12px 60px;
    font-size: 16px;
    color: #fff;
  }
  input::placeholder {
    border: none;
    background: transparent;

    font-size: 16px;
    color: #fff;
  }

  .react-geocoder-results {
    position: absolute;
    top: 100%;
    left: 0%;
    width: 100%;
    height: auto;
    z-index: 100;
    background: #fff;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  }

  .react-geocoder-item {
    padding: 8px 12px;
    transition: ease all 0.2s;
    cursor: pointer;

    &:hover {
      background: #f2f2f2;
    }
  }
`

const Sidebar = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: ${(props) => (props.hasPagination ? "36px" : 0)};
  background: #fff;

  @media (min-width: 960px) {
    width: 350px;
    height: calc(100vh - ${headerHeight - 30}px);
    overflow: auto;
    order: 1;
  }

  @media (min-width: ${menuBreakpoint}px) {
    height: calc(100vh - ${headerHeight}px);
  }
`

const SidebarItem = styled.div`
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid ${theme.colors.background.dark};
  transition: ease all 0.2s;
  cursor: pointer;

  &:hover {
    background: ${theme.colors.background.dark};
  }
`

const SidebarTitle = styled(Typography)`
  && {
    font-size: 18px;
  }
`

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 300px;

  .mapboxgl-map {
    height: 100%;
  }

  @media (min-width: 960px) {
    order: 2;
    width: calc(100% - 350px);
    height: calc(100vh - ${headerHeight - 30}px);
  }

  @media (min-width: ${menuBreakpoint}px) {
    height: calc(100vh - ${headerHeight}px);
  }
`

const SmallMapContainer = styled.div`
  width: 100%;

  .mapboxgl-map {
    height: 220px;
  }

  @media (min-width: 768px) {
    width: 50%;

    .mapboxgl-map {
      height: 100%;
    }
  }
`

const DialogItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-top: 1px solid #eee;

  svg {
    margin-right: 15px;
    width: 20px;

    path {
      fill: ${theme.colors.text.dark};
    }
  }

  a {
    display: flex;
    align-content: center;
    text-decoration: none;
  }
`

const Content = styled.div`
  a {
    display: block;
  }
`

// const Tooltip = styled.div`
//   position: absolute;
//   bottom: 100%;
//   background: #fff;
//   border-radius: 5px;
//   padding: 5px;
//   z-index: 99;
//   > span {
//     white-space: nowrap;
//   }
//   display: none;
// `

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    width: 100%;

    @media (min-width: 768px) {
      width: 50%;
    }
  }
`

export default DealerLocator
