import React from "react"
import styled from "styled-components"
import { Grid, Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import app components
import { Edges, Socials, Spacer } from "components"
import Form from "components/form"
import FooterMenu from "components/menu/FooterMenu"

export default () => {
  const {
    themeOptions: {
      siteOptions: { certifications }
    }
  } = useOptionsQuery()

  return (
    <Container>
      <Spacer pt={{ xs: 40, sm: 80 }} pb={{ xs: 40, sm: 80 }}>
        <Edges size="lg">
          <Grid container justify="space-between">
            <Grid item>
              <Grid container>
                <Spacer mb={{ xs: 40 }}>
                  <FooterMenu
                    headline={`Highfield`}
                    name={process.env.GATSBY_ACTIVE_LANGUAGE === "AU" ? "footer-menu-1-au" : "footer-menu-1"}
                  />
                </Spacer>
                <Spacer mb={{ xs: 40 }}>
                  <FooterMenu
                    headline={`Contact`}
                    name={process.env.GATSBY_ACTIVE_LANGUAGE === "AU" ? "footer-menu-2-au" : "footer-menu-2"}
                  />
                </Spacer>
              </Grid>
              <Spacer mt={{ sm: 10 }} mb={{ sm: 20 }}>
                <Socials size="lg" />
              </Spacer>
            </Grid>
            <div>
              <Spacer mb={{ xs: 10 }}>
                <Typography variant="palette" children={`Sign Up for our Newsletter`} />
              </Spacer>
              <StyledForm
                // formId={process.env.GATSBY_ACTIVE_LANGUAGE === "AU" ? 10 : 6}
                formId={15}
                hideTitle={true}
                buttonText="OK"
              />
              {certifications && (
                <CertificationsContainer>
                  <CertTitle>CERTIFICATIONS</CertTitle>
                  <Certifications>
                    {certifications.map((certificate, index) => {
                      const image = certificate?.image?.localFile && getImage(certificate.image.localFile)
                      return (
                        <CertImageWrap key={index}>
                          <GatsbyImage image={image}></GatsbyImage>
                        </CertImageWrap>
                      )
                    })}
                  </Certifications>
                </CertificationsContainer>
              )}
            </div>
          </Grid>
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled.div`
  background: #000;
  color: #fff;
`

const CertImageWrap = styled.div`
  margin-right: 35px;
`
const CertTitle = styled.div`
  font-weight: 700;
  font-family: "Poppins";
  font-size: 16px;
  margin-right: auto;
  margin-bottom: 45px;
`

const Certifications = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: auto;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

const CertificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 60px;
`

const StyledForm = styled(Form)`
  && {
    width: 100%;
    margin-top: 20px;

    @media (min-width: 800px) {
      width: auto;
      margin-top: 0;
    }

    .gravityForm {
      display: flex;
    }

    .field {
      margin-bottom: 0;
      width: 100%;

      input {
        color: #fff;
      }

      @media (min-width: 800px) {
        width: 300px;
      }
    }

    button {
      background-color: #fff;
      border: 1px solid #fff;
      color: #000;
    }
  }
`

const useOptionsQuery = () => {
  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          themeOptions {
            siteOptions {
              certifications {
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(quality: 100, height: 64)
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return wp
}
