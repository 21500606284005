import React, { useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import HamburgerMenu from "react-hamburger-menu"
import { Grid, Typography } from "@material-ui/core"

// import app components
import { DesktopMenu, MobileMenu, Edges, Socials } from "components"
import Logo from "icons/logo.svg"
import { useStore } from "store"
import * as theme from "theme"

export const headerHeight = 120
export const menuBreakpoint = 1280

export default (props) => {
  const { path } = props

  const [
    {
      appState: { menu, skipTabHeader }
    },
    dispatch
  ] = useStore()

  useEffect(() => {
    dispatch({ type: "SET_MENU", payload: false })
  }, [path, dispatch])

  return (
    <>
      <Placeholder />

      <TopBar skipTabHeader={skipTabHeader}>
        <Edges size="lg">
          <Grid container justify="space-between">
            <Socials small />

            <TopBarLinks component="div" variant="subtitle1">
              {process.env.GATSBY_ACTIVE_LANGUAGE === "EN" && (
                <StoreLink to={`https://shop.highfieldnorthamerica.com/`} children={`Highfield online store`} />
              )}
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://www.highfieldboats.com/`}
                children={`Global Website`}
                aria-label="Global Website"
              />
              <Link to={`/registration`} children={`Warranty Registration`} />
            </TopBarLinks>
          </Grid>
        </Edges>
      </TopBar>

      <Container skipTabHeader={skipTabHeader}>
        <Edges size="lg">
          <Nav>
            <LogoContainer>
              <Link to="/" onClick={() => dispatch({ type: "SET_MENU", payload: false })} title="Back to Homepage">
                <Logo />
              </Link>
            </LogoContainer>

            <Right>
              <DesktopMenu className="desktop-menu" />

              <HamburgerMenuContainer onClick={() => dispatch({ type: "TOGGLE_MENU" })}>
                <HamburgerMenu
                  color="#fff"
                  isOpen={menu}
                  width={30}
                  height={20}
                  strokeWidth={2}
                  menuClicked={() => ""}
                />
              </HamburgerMenuContainer>
            </Right>
          </Nav>
        </Edges>
      </Container>

      <MobileContainer>
        <MobileMenu />
      </MobileContainer>
    </>
  )
}

const StoreLink = styled(Link)`
  background: #37ab9c;
  color: #fff !important;
  padding: 15px;
  font-weight: 500;
`
const Placeholder = styled.div`
  height: ${headerHeight - 30}px;

  @media (min-width: ${menuBreakpoint}px) {
    height: ${headerHeight}px;
  }
`

const TopBar = styled.div`
  position: ${({ skipTabHeader }) => (skipTabHeader ? "absolute" : "fixed")};
  z-index: 999;
  top: 0;
  left: 0;
  height: 30px;
  width: 100%;
  align-items: center;
  background: #fff;
  display: none;

  @media (min-width: ${menuBreakpoint}px) {
    display: block;
  }
`

const MobileContainer = styled.div`
  display: block;

  @media (min-width: ${menuBreakpoint}px) {
    display: none;
  }
`

const TopBarLinks = styled(Typography)`
  && {
    a {
      margin: 0 5px 0 20px;
      color: ${theme.colors.primary};
      font-size: 12px;
    }
  }
`

const Container = styled.div`
  position: ${({ skipTabHeader }) => (skipTabHeader ? "absolute" : "fixed")};
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 999;
  width: 100%;
  height: 90px;
  background: ${theme.colors.primary};

  @media (min-width: ${menuBreakpoint}px) {
    top: 30px;
  }
`

const Right = styled.div`
  display: flex;
  align-items: center;
`

const LogoContainer = styled.div`
  min-width: 260px;
  padding-top: 7px;
  a {
    text-decoration: none;
  }
`

const HamburgerMenuContainer = styled.button`
  background: none;
  border: none;
  padding: 15px;
  transform: translateX(15px);
  cursor: pointer;

  @media (min-width: ${menuBreakpoint}px) {
    display: none;
  }
`

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .desktop-menu {
    @media (max-width: ${menuBreakpoint - 1}px) {
      display: none;
    }
  }
`
