import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import Parser from "html-react-parser"
import { Typography } from "@material-ui/core"

// import app components
import { BackgroundImage, Spacer } from "components"
import useMenuItems from "./useMenuItems"
import * as theme from "theme"

import ChevronDown from "icons/chevron-down.svg"

const DesktopMenu = (props) => {
  const items = useMenuItems(process.env.GATSBY_ACTIVE_LANGUAGE === "AU" ? "desktop-main-menu-au" : "desktop-main-menu")

  const [pointer, setPointer] = useState(true)
  const [isHoverActive, setIsHoverActive] = useState(false)

  const handleSubmenuClick = () => {
    setPointer(false)
  }

  useEffect(() => {
    setTimeout(() => setPointer(true), 100)
    setIsHoverActive(false)
  }, [pointer])

  return (
    <Menu {...props}>
      {items &&
        items.map(({ id, url, label, childItems, cssClasses }) => (
          <MenuItem
            key={id}
            pointer={pointer}
            onFocus={() => setIsHoverActive(id)}
            onMouseEnter={() => setIsHoverActive(id)}
            onMouseLeave={() => setIsHoverActive(false)}
          >
            {childItems && childItems.nodes.length ? (
              <>
                {url === "#" ? (
                  <MenuLinkContainer>
                    <Typography color="inherit" component="div" variant="subtitle1" children={Parser(label)} />
                    <ChevronDown />
                  </MenuLinkContainer>
                ) : (
                  <MenuLink to={url} activeStyle={{ color: theme.colors.primary }}>
                    <Typography color="inherit" component="div" variant="subtitle1" children={Parser(label)} />
                    <ChevronDown />
                  </MenuLink>
                )}

                {pointer && (
                  <SubMenu className={` ${cssClasses.join(" ")}`} isHoverActive={isHoverActive} id={id}>
                    {childItems.nodes.map((o, i) => {
                      return cssClasses.includes("boats") ? (
                        <SubMenuItemImage key={i} to={o.url} activeClassName="active" onClick={handleSubmenuClick}>
                          <ImageContainer>
                            {o.acf.image && (
                              <div>
                                <BackgroundImage backgroundSize="contain" image={o.acf.image} />
                              </div>
                            )}
                          </ImageContainer>
                          <Spacer pl={{ xs: 10 }} pr={10}>
                            <Typography
                              color="inherit"
                              component="div"
                              variant="subtitle1"
                              children={Parser(o.label)}
                            />
                            {o.acf.description && (
                              <Typography color="inherit" component="div" children={Parser(o.acf.description)} />
                            )}
                            {o.acf.highlight && (
                              <Highlight
                                className="highlight"
                                color="inherit"
                                component="div"
                                children={Parser(o.acf.highlight)}
                              />
                            )}
                          </Spacer>
                        </SubMenuItemImage>
                      ) : (
                        <SubMenuItem key={i} to={o.url} activeClassName="active" onClick={handleSubmenuClick}>
                          <Typography color="inherit" component="div" variant="subtitle1" children={Parser(o.label)} />
                        </SubMenuItem>
                      )
                    })}
                  </SubMenu>
                )}
              </>
            ) : (
              <MenuLink to={url} activeClassName="active" className={cssClasses.join(" ")}>
                <Typography color="inherit" component="div" variant="subtitle1" children={Parser(label)} />
              </MenuLink>
            )}
          </MenuItem>
        ))}
    </Menu>
  )
}
export default DesktopMenu

const Menu = styled.div`
  display: flex;
  a {
    text-decoration: none;
  }
`
const MenuItem = styled.div`
  display: flex;
  position: relative;
  background: none;
  border: none;

  /* ${({ pointer }) =>
    pointer &&
    css`
      &:hover {
        .sub-menu {
          opacity: 1;
          pointer-events: all;
        }
      }
    `} */

  /* .sub-menu {
    display: none;
    pointer-events: none;
  } */

  /* &:hover,
  &:focus {
    ${(props) =>
    props.isHoverActive &&
    `
      .sub-menu {
            display: flex;

          pointer-events: all;
  }
    `}
  } */
`

const menuItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  padding: 0 10px;
  margin: 0 5px;
  color: ${theme.colors.text.light};
  text-align: center;
  cursor: pointer;
`
const MenuLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  padding: 0 10px;
  margin: 0 5px;
  color: ${theme.colors.text.light};
  text-align: center;
  cursor: pointer;

  &.active {
    color: ${theme.colors.secondary};

    &.find-a-dealer {
      color: ${theme.colors.primary};
    }
  }

  &.find-a-dealer {
    padding: 0 20px;
    background: ${theme.colors.secondary};
    transition: ease all 0.2s;

    &:hover {
      opacity: 0.9;
    }
  }
`
const MenuLinkContainer = styled.button`
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  padding: 0 10px;
  margin: 0 5px;
  color: ${theme.colors.text.light};
  text-align: center;
  cursor: pointer;
`
const SubMenu = styled.div`
  display: ${(props) => (props.isHoverActive === props.id ? "flex" : "none")};
  position: absolute;
  flex-direction: column;
  top: 100%;
  left: calc(50% + 15px);
  transform: translateX(-50%);
  background: ${theme.colors.background.light};
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);

  &:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -8px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
  }

  &.boats {
    width: 750px;
    padding-bottom: 20px;
    flex-direction: row;
    flex-wrap: wrap;
  }
`
const SubMenuItem = styled(Link)`
  padding: 15px 30px;
  flex-shrink: 0;
  white-space: nowrap;
  color: ${theme.colors.text.dark};

  &:hover,
  &.active {
    color: ${theme.colors.secondary};
  }
`
const SubMenuItemImage = styled(Link)`
  width: 250px;
  margin-top: 20px;
  text-align: center;
  color: ${theme.colors.primary};

  &.active,
  &:hover {
    color: ${theme.colors.secondary};
  }
`
const Highlight = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: bold;
    color: ${theme.colors.secondary};
  }
`
const ImageContainer = styled.div`
  width: 100%;
  padding: 0 20px;

  > div {
    position: relative;
    width: 100%;
    height: 120px;
  }
`
