import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"
import { graphql, useStaticQuery, Link } from "gatsby"

// import app components
import { BackgroundImage } from "components"
import * as theme from "theme"

const Boats = (props) => {
  const { category, exclude } = props

  const { nodes: boats } = useBoatsQuery()

  let visibleBoats = boats.filter((o) => {
    if (o?.boatTypes?.nodes && !o.boatTypes.nodes.find((p) => p.databaseId === category.databaseId)) {
      return null
    }

    return o
  })

  if (exclude) {
    visibleBoats = visibleBoats.filter((o) => o.id !== exclude)
  }

  return (
    <Container>
      <Items>
        {visibleBoats &&
          visibleBoats.map((o) => {
            return (
              o?.language.code === process.env.GATSBY_ACTIVE_LANGUAGE && (
                <Item key={o.databaseId} to={o.uri}>
                  <TitleContainer>
                    <ItemTitle variant="h4" children={o.title} />

                    {o?.boat?.badge?.label && (
                      <TitleBadge
                        style={{
                          backgroundColor: o?.boat?.badge?.backgroundColor,
                          color: o?.boat?.badge?.textColor || "#FFF"
                        }}
                      >
                        {o?.boat?.badge?.label}
                      </TitleBadge>
                    )}
                  </TitleContainer>
                  {o?.boat?.subTitle && <ItemSubtitle variant="h5" children={o?.boat?.subTitle}></ItemSubtitle>}
                  <ImageContainer>
                    {o.featuredImage.node && <BackgroundImage backgroundSize="contain" image={o.featuredImage.node} />}
                  </ImageContainer>

                  {o?.boat?.specs?.overallLength?.column1 && (
                    <ItemLength>
                      {o?.boat?.specs?.overallLength?.column1 && o.boat.specs.overallLength.column1}
                      {o?.boat?.specs?.overallLength?.column1 && o.boat.specs.overallLength.column2 && ` / `}
                      {o?.boat?.specs?.overallLength?.column2 && o.boat.specs.overallLength.column2}
                    </ItemLength>
                  )}
                </Item>
              )
            )
          })}
      </Items>
    </Container>
  )
}

const Container = styled.div`
  background: #f7f7f7;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  && {
    margin-bottom: 20px;
  }
`

const TitleBadge = styled.div`
  border-radius: 5px;
  color: white;
  padding: 1px 5px;
  white-space: nowrap;
  margin-left: 15px;
  font-weight: 700;
  font-family: "Futura";
  word-break: break-word;
  text-transform: uppercase;
  pointer-events: none;
  list-style: none;
  @media screen and (max-width: 1500px) {
    margin-left: 100%;
    margin-right: 100%;
    margin-top: 5px;
  }

  font-size: 15px !important;

  line-height: 22px !important;
  /* outline: none; */
  text-decoration: none;
  display: flex;
  flex-direction: column;
  text-align: center;
`
const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Item = styled(Link)`
  width: 100%;
  padding: 30px 30px 60px;
  text-align: center;
  text-decoration: none;
  transition: ease background 0.2s;

  @media screen and (min-width: 640px) {
    width: 50%;
  }

  @media screen and (min-width: 960px) {
    width: calc(100% / 3);
  }

  &:hover {
    background: #e4e4e4;
  }
`

const ItemTitle = styled(Typography)`
  white-space: nowrap;
  && {
    font-weight: 700;
    color: ${theme.colors.primary};
  }
`
const ItemSubtitle = styled(Typography)`
  && {
    font-weight: 500;
    font-size: 20px;
    color: ${theme.colors.primary};
    margin-bottom: 20px;
  }
`

const ItemLength = styled(Typography)`
  && {
    font-weight: bold;
    color: ${theme.colors.secondary};
  }
`

const ImageContainer = styled.div`
  position: relative;
  height: 120px;
  margin-bottom: 20px;
`

const useBoatsQuery = () => {
  const { allWpBoat } = useStaticQuery(
    graphql`
      {
        allWpBoat(sort: { order: DESC, fields: title }) {
          nodes {
            language {
              code
            }
            id
            title
            databaseId
            uri
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 400, layout: CONSTRAINED)
                  }
                }
              }
            }
            boatTypes {
              nodes {
                name
                databaseId
              }
            }
            boat {
              subTitle
              badge {
                label
                backgroundColor
                textColor
              }
              specs {
                overallLength {
                  column1
                  column2
                }
              }
            }
          }
        }
      }
    `
  )
  return allWpBoat
}

export default Boats
