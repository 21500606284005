import "unfetch/polyfill"
// Required to enable image uploads with react gravity forms
import "base64-js"
import "./src/theme/global.css"

export const onInitialClientRender = () => {
  // Scroll to location.hash on page load
  if (typeof document !== `undefined` && typeof window !== `undefined`) {
    const hashElement = document.getElementById(window.location.hash.replace("#", ""))
    if (!!hashElement) {
      hashElement.scrollIntoView()
      window.scrollBy(0, -90)
    }
  }
}

export const onClientEntry = () => {
  if (window.location.hostname === "www.highfieldnorthamerica.com") {
    window.location.replace(`https://www.highfieldboats.com${window.location.pathname}`)
  }
}
