import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"

// import app components
import { Edges, Button, Spacer } from "components"
import * as theme from "theme"

const Downloads = props => {
  const { headline, items, ...rest } = props

  return (
    <Container {...rest}>
      <Spacer pt={{ xs: 40, sm: 60 }} pb={{ xs: 40, sm: 60 }}>
        <Edges size="md">
          {headline && (
            <Spacer mb={30}>
              <Typography variant="h4" children={headline} />
            </Spacer>
          )}
          {items &&
            items.map((o, i) => {
              return (
                <Item key={i}>
                  <Typography variant="h5" children={o.title || ""} />
                  {o.file && (
                    <Button
                      href={o.file.mediaItemUrl}
                      children={`Download`}
                      variant="contained"
                      color="primary"
                      target="_blank"
                      small
                    />
                  )}
                </Item>
              )
            })}
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled.div`
  background: ${theme.colors.background.dark};
`

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #e0e0e0;
`

export default Downloads
