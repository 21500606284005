import React from "react"
import styled from "styled-components"

import OpenInNew from "@material-ui/icons/OpenInNew"
import Close from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"

import YouTube from "./YouTube"
import { BackgroundImage } from "components"
import { useStore } from "store"

export default function Slide(props) {
  const dispatch = useStore()[1]

  const {
    slide: { caption, image, link },
    slide,
    slideCount
  } = props

  const isYoutube = link?.url && link?.url.includes("youtu")

  return (
    <Container {...slide} slideCount={slideCount}>
      {!!isYoutube && (
        <LinkToPost href={link.url} target="_blank" rel="noopener referrer">
          <Tooltip title="Watch on YouTube" placement="right">
            <IconButton aria-label="Watch on YouTube">
              <OpenInNew />
            </IconButton>
          </Tooltip>
        </LinkToPost>
      )}
      <ExitButton
        aria-label="Close"
        onClick={() =>
          dispatch({
            type: "CLOSE_LIGHTBOX"
          })
        }
      >
        <IconButton aria-label="Close">
          <Close />
        </IconButton>
      </ExitButton>

      {!!isYoutube && <YouTube video={link.url} />}

      {!isYoutube && (
        <ImageContainer slideCount={slideCount}>
          {caption && <Caption variant="caption" children={caption} />}

          {!!image && !!image.localFile ? (
            <BackgroundImage backgroundSize="contain" {...{ image }} />
          ) : (
            <img src={image} alt="" />
          )}
        </ImageContainer>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  height: 80vh;
  position: relative;
  top: 10vh;
`
const ImageContainer = styled.div`
  height: 80vh;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`

const ExitButton = styled.div`
  position: fixed;
  z-index: 10;
  top: 10px;
  right: 10px;

  svg {
    fill: white;
  }
`
const LinkToPost = styled.a`
  z-index: 1;
  text-decoration: none;
  color: inherit;
  position: absolute;
  top: -50px;
  left: 0;
  cursor: pointer;
  svg {
    fill: white;
  }
`

const Caption = styled(Typography)`
  && {
    position: fixed;
    z-index: 10;
    top: 20px;
    left: 10px;
    padding: 5px 10px;
    max-width: calc(100% - 40px);
    color: #fff;
  }
`
