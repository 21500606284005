import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import { IconButton, Typography } from "@material-ui/core"
import ArrowBack from "@material-ui/icons/ArrowBackIos"
import ArrowForward from "@material-ui/icons/ArrowForwardIos"

// import app components
import { BackgroundImage } from "components"
import { useStore } from "store"
import PlayIcon from "icons/play.svg"

const Gallery = props => {
  const { images } = props

  const [, dispatch] = useStore()

  const settings = {
    dots: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: images && images.length < 3 ? images.length : 3,
    slidesToScroll: 1,
    infinite: images && images.length > 3,
    nextArrow: (
      <StyledIconButton>
        <ArrowForward />
      </StyledIconButton>
    ),
    prevArrow: (
      <StyledIconButton>
        <ArrowBack />
      </StyledIconButton>
    ),
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  const showLightBox = index => {
    dispatch({
      type: "SET_LIGHTBOX",
      payload: {
        open: true,
        slide: index,
        slides: images.map(o => {
          return { ...o, link: { url: o.youtubeUrl } }
        }),
        options: {
          thumbs: false
        }
      }
    })
  }

  return (
    <Container>
      {images && (
        <Slider {...settings}>
          {images.map((o, i) => {
            return (
              <div key={i}>
                <Item onClick={() => showLightBox(i)}>
                  <BackgroundImage image={o.image} animated />

                  {o.caption && <Caption variant="caption" children={o.caption} />}

                  {o.youtubeUrl && (
                    <PlayIconButton>
                      <PlayIcon />
                    </PlayIconButton>
                  )}
                </Item>
              </div>
            )
          })}
        </Slider>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  .slick-prev {
    left: 10px !important;
    position: absolute;
  }

  .slick-next {
    right: 10px !important;
    position: absolute;
  }
`

const Item = styled.div`
  position: relative;
  height: 30vw;
  min-height: 300px;
  max-height: 800px;
  width: 100%;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    pointer-events: none;
  }
`

const StyledIconButton = styled(IconButton)`
  && {
    svg {
      path {
        fill: #fff;
      }
    }
  }
`

const PlayIconButton = styled(IconButton)`
  && {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    svg {
      width: 120px !important;
      height: 120px !important;

      path {
        fill: #fff;
      }
    }
  }
`

const Caption = styled(Typography)`
  && {
    position: absolute;
    z-index: 10;
    right: 10px;
    bottom: 10px;
    padding: 5px 10px;
    max-width: calc(100% - 20px);
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
  }
`

export default Gallery
