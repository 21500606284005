import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"
import { Link } from "gatsby"

// import app components
import { BackgroundImage, Spacer } from "components"

const NewsPost = (props) => {
  const {
    image,
    imagePadding = 0,
    imageSize = "cover",
    imageBackgroundColor = "transparent",
    headline,
    url,
    ...rest
  } = props

  return (
    <Container {...rest}>
      <Link to={url}>
        <Spacer mb={20}>
          <ImageContainer backgroundColor={imageBackgroundColor} padding={imagePadding}>
            <div>{image && <BackgroundImage image={image} backgroundSize={imageSize} />}</div>
          </ImageContainer>
        </Spacer>
        {headline && (
          <Spacer mb={20}>
            <Typography variant="h5" children={headline} />
          </Spacer>
        )}
        <ReadMore children={`Read more`} color="primary" />
      </Link>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const ImageContainer = styled.div`
  z-index: 1;
  height: 220px;
  width: 100%;
  background-color: ${(props) => props.backgroundColor};
  padding: ${(props) => props.padding};

  > div {
    position: relative;
    height: 100%;
  }
`

const ReadMore = styled(Typography)`
  && {
    font-weight: bold;
  }
`

export default NewsPost
