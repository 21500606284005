import React from "react"
import Helmet from "react-helmet"

const HrefLang = (props) => {
  const { translations, language, location, defaultLanguage } = props
  // const { languages } = useLanguagesQuery()

  const languages = {
    EN: `https://highfieldnorthamerica.com`,
    AU: `https://www.highfieldboats.com.au`
  }

  return (
    <Helmet htmlAttributes={{ lang: language?.slug || defaultLanguage?.slug }}>
      {language ? (
        <link rel="alternate" href={location.href} hreflang={language.locale.replace("_", "-").toLowerCase()} />
      ) : (
        <link rel="alternate" href={location.href} hreflang="x-default" />
      )}

      {translations &&
        translations.map((translation, index) => {
          const languageCode = translation.language.slug.toUpperCase()
          const hreflang = translation.language.locale.replace("_", "-").toLowerCase()
          const pathname = `${languages[languageCode]}${translation.uri}`

          return <link key={index} rel="alternate" href={pathname} hreflang={hreflang} />
        })}
    </Helmet>
  )
}

// const useLanguagesQuery = () => {
//   const {
//     wp: {
//       themeOptions: { siteOptions }
//     }
//   } = useStaticQuery(
//     graphql`
//       query {
//         wp {
//           themeOptions {
//             siteOptions {
//               languages {
//                 title
//                 url
//                 languageCode
//               }
//             }
//           }
//         }
//       }
//     `
//   )
//   return siteOptions
// }

export default HrefLang
