import { useStaticQuery, graphql } from "gatsby"

export const useMenuItems = slug => {
  const {
    allWpMenu: { menus }
  } = useStaticQuery(graphql`{
  allWpMenu {
    menus: nodes {
      id
      slug
      menuItems {
        nodes {
          parentDatabaseId
          id
          label
          url
          cssClasses
          childItems {
            nodes {
              id
              label
              url
              acf {
                description
                highlight
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 300, layout: CONSTRAINED)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`)

  const menu = !!menus && menus.filter(menu => menu.slug === slug)[0]

  const menuItems = menu?.menuItems?.nodes && menu.menuItems.nodes.filter(item => item.parentDatabaseId === 0)

  return menuItems
}

export default useMenuItems
