import React from "react"
import styled from "styled-components"
import { Typography, Accordion as MuiAccordion, AccordionSummary, AccordionDetails } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

// import app components
import { Edges, Textarea, Spacer } from "components"
import * as theme from "theme"

const Accordion = (props) => {
  const { items } = props

  return (
    <Container>
      <Spacer pt={{ xs: 50 }} pb={{ xs: 50 }}>
        <Edges size="md">
          {items &&
            items.map((o, i) => {
              return (
                <StyledAccordion key={i}>
                  <StyledAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel-${i + 1}-content`}
                    id={`panel-${i + 1}-header`}
                  >
                    {o.title && <Typography variant="h6" children={o.title} color="inherit" />}
                  </StyledAccordionSummary>
                  <StyledAccordionDetails>{o.text && <Textarea content={o.text} />}</StyledAccordionDetails>
                </StyledAccordion>
              )
            })}
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled.div``

const StyledAccordion = styled(MuiAccordion)`
  margin-bottom: 10px;
`

const StyledAccordionSummary = styled(AccordionSummary)`
  && {
    min-height: auto;
    background: ${theme.colors.primary};
    color: #fff;

    &.Mui-expanded {
      background: ${theme.colors.secondary};
      color: ${theme.colors.primary};
    }
    &:focus {
      background: ${theme.colors.secondary};
      color: ${theme.colors.primary};
    }

    .MuiAccordionSummary-content {
      margin: 12px 0;
    }

    svg {
      path {
        fill: #fff;
      }
    }
  }
`

const StyledAccordionDetails = styled(AccordionDetails)`
  && {
    padding: 16px;
    background: ${theme.colors.background.dark};
  }
`

export default Accordion
