import React from "react"
import styled, { css } from "styled-components"
import { Typography } from "@material-ui/core"
import { Link } from "gatsby"

import YouTube from "react-youtube"

// import app components
import { BackgroundImage, Spacer } from "components"
import BackgroundVideo from "components/backgroundVideo"

const ImageLinks = props => {
  const { images, layout, alignment } = props

  const largeImage = layout === "1 large / 2 small" && !!images?.[0] && images[0]
  const sideImages = layout === "1 large / 2 small" && !!images && [...images].slice(1)

  return (
    <Container alignment={alignment} layout={layout}>
      {layout === "1 large / 2 small" ? (
        <>
          <LargeImage>
            {largeImage && (
              <Item to={largeImage?.link?.url} layout={layout} largeImage>
                {largeImage?.image && <BackgroundImage animated image={largeImage.image} />}
                <Video {...largeImage} className="video-container" />

                <Content>
                  {largeImage.topLabel && (
                    <TopLabel color="inherit">
                      <span>{largeImage.topLabel}</span>
                    </TopLabel>
                  )}

                  {largeImage?.headline && (
                    <Headline children={largeImage.headline} color="inherit" variant={largeImage?.fontStyle || "h4"} />
                  )}

                  {largeImage.description && (
                    <Spacer mt={10}>
                      <Typography children={largeImage.description} color="inherit" />
                    </Spacer>
                  )}
                </Content>
              </Item>
            )}
          </LargeImage>
          <SideImages>
            {sideImages &&
              sideImages.map((o, i) => {
                return (
                  <Item
                    key={i}
                    index={i}
                    to={o.link.url}
                    layout={layout}
                    sideImage
                    lastItem={i === sideImages.length - 1}
                  >
                    {o.image && <BackgroundImage animated image={o.image} />}

                    <Video {...o} className="video-container" />

                    <Content>
                      {o.topLabel && (
                        <TopLabel color="inherit">
                          <span>{o.topLabel}</span>
                        </TopLabel>
                      )}

                      {o.headline && <Headline children={o.headline} color="inherit" variant={o?.fontStyle || "h4"} />}

                      {o.description && (
                        <Spacer mt={10}>
                          <Typography children={o.description} color="inherit" />
                        </Spacer>
                      )}
                    </Content>
                  </Item>
                )
              })}
          </SideImages>
        </>
      ) : (
        <>
          {images &&
            images.map((o, i) => {
              return (
                <Item key={i} index={i} to={o.link.url} layout={layout} lastItem={i === images.length - 1}>
                  {o.image && <BackgroundImage animated image={o.image} />}
                  <Video {...o} className="video-container" />

                  <Content>
                    {o.topLabel && (
                      <TopLabel color="inherit">
                        <span>{o.topLabel}</span>
                      </TopLabel>
                    )}

                    {o.headline && <Headline children={o.headline} color="inherit" variant={o?.fontStyle || "h4"} />}

                    {o.description && (
                      <Spacer mt={10}>
                        <Typography children={o.description} color="inherit" />
                      </Spacer>
                    )}
                  </Content>
                </Item>
              )
            })}
        </>
      )}
    </Container>
  )
}

const Video = props => {
  const { mediaVariant, video, videoUpload, className } = props
  let videoId = !!video ? video.includes("youtu") && video.substring(video.lastIndexOf("/") + 1) : ""

  return (
    <>
      {mediaVariant === "file"
        ? (videoUpload?.localFile?.publicURL || videoUpload?.mediaItemUrl) && (
            <VideoBackground className={className}>
              <BackgroundVideo src={videoUpload?.localFile?.publicURL || videoUpload?.mediaItemUrl} />
            </VideoBackground>
          )
        : video && (
            <VideoBackground className={className}>
              <VideoForeground>
                <YouTube
                  videoId={videoId}
                  opts={{
                    playerVars: {
                      autoplay: 1,
                      controls: 0,
                      rel: 0,
                      showinfo: 0,
                      mute: 1,
                      loop: 1,
                      playlist: videoId
                    }
                  }}
                />
              </VideoForeground>
            </VideoBackground>
          )}
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #fff;
  margin-top: 4px;
  margin-bottom: 4px;

  ${({ alignment }) =>
    alignment === "default"
      ? css`
          flex-direction: row;
        `
      : css`
          flex-direction: row-reverse;
        `}
`

const Content = styled.div`
  position: relative;
  z-index: 10;
  color: #fff;
  text-align: center;
`

const Item = styled(({ lastItem, largeImage, sideImage, layout, ...rest }) => <Link {...rest} />)`
  overflow: hidden;
  position:relative;
  padding: 20px;
  cursor: pointer;
 
  ${({ lastItem }) =>
    !lastItem &&
    css`
      @media (max-width: 639px) {
        margin-bottom: 4px;
      }
    `}

  ${({ largeImage }) =>
    largeImage &&
    css`
      height: 20vw;
      min-height: 300px;
      max-height: 800px;

      @media (max-width: 999px) {
        margin-bottom: 4px;
      }

      @media (min-width: 1000px) {
        max-height: unset;
        height: 100%;
      }
    `}

    ${({ sideImage }) =>
      sideImage &&
      css`
        min-height: 300px;
        max-height: 800px;
        height: 20vw;
        ${({ lastItem }) =>
          !lastItem &&
          css`
            margin-bottom: 4px;
          `}
      `}

  ${({ layout }) => {
    switch (layout) {
      case "3 columns grid":
        return css`
          height: 26vw;
          min-height: 300px;
          max-height: 800px;
          width: 100%;

          @media (min-width: 640px) {
            width: calc((100% / 3) - 3px);
          }
        `
      case "2 column grid":
        return css`
          height: 35vw;
          min-height: 300px;
          max-height: 800px;
          width: 100%;

          @media (min-width: 640px) {
            width: calc(50% - 2px);
          }
        `
      case null:
        return css`
          height: 35vw;
          min-height: 300px;
          max-height: 800px;
          width: 100%;
          @media (min-width: 640px) {
            width: calc(50% - 2px);
          }
        `

      default:
    }
  }}

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    pointer-events: none;
  }


  .video-container {
      transition: ease all 0.8s;
       &:before {
          transition: ease all 0.2s;
          content: "";
          position: absolute;
          z-index: 2;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.2);
          pointer-events: none;
          opacity:0.2;
        }
  }

  &:hover {
    .video-container {
       transform: scale(1.02);
        &:before {
          opacity: 1;
        }
    }
  }
`

const decoration = css`
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 2px;
  background: #fff;
`

const TopLabel = styled(Typography)`
  && {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 5px;

    span {
      position: relative;

      &:before {
        ${decoration}
        left: -50px;
      }

      &:after {
        ${decoration}
        right: -50px;
      }
    }
  }
`

const Headline = styled(Typography)`
  && {
    position: relative;
    z-index: 2;
    text-align: center;
    font-weight: normal;
    color: #fff;
  }
`

const LargeImage = styled.div`
  width: 100%;
  @media (min-width: 1000px) {
    width: calc(60% - 2px);
  }
`

const SideImages = styled.div`
  width: 100%;
  @media (min-width: 1000px) {
    width: calc(40% - 2px);
  }
`

const VideoBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
`

const VideoForeground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  @media (min-aspect-ratio: 16/9) {
    height: 300%;
    top: -100%;
  }

  @media (max-aspect-ratio: 16/9) {
    width: 300%;
    left: -100%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
`

export default ImageLinks
