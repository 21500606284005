import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"

// import app components
import { Edges, Spacer, Button, BackgroundImage, Textarea } from "components"
import * as theme from "theme"

const TextImage = props => {
  const {
    headline,
    headlineStyle,
    image,
    imageAlignment,
    imageVerticalAlignment,
    imageSize,
    text,
    button,
    backgroundColor,
    ...rest
  } = props

  return (
    <Container {...rest} backgroundColor={backgroundColor}>
      <ImageContainer alignment={imageAlignment}>
        {image && (
          <BackgroundImage verticalAlignment={imageVerticalAlignment} backgroundSize={imageSize} image={image} />
        )}
      </ImageContainer>

      <Edges size="md">
        <ContentContainer alignment={imageAlignment}>
          <Spacer pt={{ xs: 40, sm: 60 }} pb={{ xs: 40, sm: 60 }}>
            {headline && (
              <Spacer mb={30}>
                <Typography variant={headlineStyle} children={headline} />
              </Spacer>
            )}

            {text && <Textarea content={text} />}

            {button && (
              <Spacer mt={30}>
                <Button to={button.url} children={button.title} variant="outlined" color="primary" />
              </Spacer>
            )}
          </Spacer>
        </ContentContainer>
      </Edges>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  background: ${props =>
    props.backgroundColor === "grey" ? theme.colors.background.dark : theme.colors.background.light};
`

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  order: 1;
  border-bottom: 1px solid ${theme.colors.primary};

  @media (min-width: 960px) {
    position: absolute;
    top: 0;
    width: 40%;
    height: 100%;
    ${props => (props.alignment === "right" ? "right: 0" : "left: 0")};
    border-bottom: none;
  }

  &:hover {
    .icon {
      opacity: 0.8;
    }
  }
`

const ContentContainer = styled.div`
  width: 100%;

  @media (min-width: 960px) {
    width: 60%;
    min-height: 80vh;
    display: flex;
    align-items: center;
    ${props => (props.alignment === "left" ? "padding-left: 40px" : "padding-right: 40px")};
    ${props => props.alignment === "left" && "margin-left: 40%"};
  }
`

export default TextImage
