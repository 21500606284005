import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Typography, IconButton } from "@material-ui/core"
import Slider from "react-slick"
import ArrowBack from "@material-ui/icons/ArrowBackIos"
import ArrowForward from "@material-ui/icons/ArrowForwardIos"
import Parser from "html-react-parser"

// import app components
import { BackgroundImage, Button, Spacer, Edges } from "components"
import * as theme from "theme"

const Carousel = (props) => {
  const { headline, items, ...rest } = props

  const [isSlideMoving, setIsSlideMoving] = useState(false)
  const settings = {
    dots: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    afterChange: () => setIsSlideMoving(false),
    beforeChange: () => setIsSlideMoving(true),
    nextArrow: (
      <IconButton title="Next Boat" aria-label="Next Boat">
        <ArrowForward />
      </IconButton>
    ),
    prevArrow: (
      <IconButton title="Previous Boat" aria-label="Previous Boat">
        <ArrowBack />
      </IconButton>
    ),
    responsive: [
      {
        breakpoint: 960,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 3
        }
      },
      {
        breakpoint: 760,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 2
        }
      },
      {
        breakpoint: 500,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1
        }
      }
    ]
  }

  return (
    <Container {...rest} className={isSlideMoving ? "slide-changing" : ""}>
      <Edges size="md">
        <Spacer pt={{ xs: 50 }} pb={{ xs: 80, lg: 50 }}>
          <Spacer mb={{ xs: 40 }}>{headline && <Headline variant="h4" children={headline} />}</Spacer>

          <Slider {...settings}>
            {items.map((o, index) => {
              return (
                <div key={index}>
                  <Item to={o.button.url}>
                    {o.headline && (
                      <Spacer mb={{ xs: 20 }}>
                        <ItemHeadline variant="body2" children={o.headline} />
                      </Spacer>
                    )}

                    {o.image && (
                      <Spacer mb={{ xs: 30 }}>
                        <ImageContainer>
                          <BackgroundImage image={o.image} backgroundSize="contain" />
                        </ImageContainer>
                      </Spacer>
                    )}

                    {o.description && (
                      <Spacer mb={{ xs: 5 }} pl={4} pr={4}>
                        <Description children={Parser(o.description)} />
                      </Spacer>
                    )}

                    {o.highlight && (
                      <Spacer mb={{ xs: 20 }}>
                        <Highlight color="inherit" component="div" children={Parser(o.highlight)} />
                      </Spacer>
                    )}

                    {o.button && (
                      <ButtonContainer>
                        <Button
                          children={o.button.title || "View Models"}
                          target={o.button.target}
                          variant="outlined"
                          aria-label={o.button.target}
                          to={o.button.url}
                          small
                        />
                      </ButtonContainer>
                    )}
                  </Item>
                </div>
              )
            })}
          </Slider>
        </Spacer>
      </Edges>
    </Container>
  )
}

const Container = styled.div`
  padding-bottom: 50px;
  .slick-dots {
    bottom: -70px;
  }
  .slick-slide:not(.slick-active) {
    visibility: hidden;
  }

  .slide-changing .slick-slide {
    visibility: visible;
  }
`
// const Box = styled.div`
//   visibility: ${(props) => (props.hidden ? "visible" : "hidden")};
//   background: ${(props) => (props.hidden ? "red" : "blue")};
// `

const Headline = styled(Typography)`
  && {
    text-align: center;
    font-weight: 700;
  }
`

const Item = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  text-decoration: none;
  color: ${theme.colors.text.dark};
  &:focus {
    outline: none;
  }
`

const ItemHeadline = styled(Typography)`
  && {
    text-transform: uppercase;
    text-align: center;
  }
`

const ImageContainer = styled.div`
  position: relative;
  height: 250px;
  max-width: 250px;
  width: 100%;
  margin: 0 auto;

  > div {
    transform: rotate(-90deg);
  }
`

const Description = styled(Typography)`
  && {
    min-height: 48px;
  }
`

const Highlight = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: bold;
    color: ${theme.colors.primary};
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

export default Carousel
