import React from "react"
import styled from "styled-components"
import { Typography } from "@material-ui/core"

// import app components
import { Edges, Spacer } from "components"
import pattern from "images/swash.png"

const Headline = props => {
  const { headline, children, ...rest } = props

  return (
    <Container {...rest}>
      <Spacer pt={{ xs: 30, sm: 60 }} pb={{ xs: 30, sm: 60 }}>
        <Edges size="md">
          {headline && <Typography variant="h3" color="inherit" children={headline} />}
          {children}
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled.div`
  background-color: #000;
  background-image: url(${pattern});
  background-position: center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  color: #fff;
  text-align: center;
`

export default Headline
