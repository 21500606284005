import React from "react"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"

// import app components
import { Edges, Spacer, BackgroundImage, Button } from "components"
import * as theme from "theme"
import { useStore } from "store"

const Features = props => {
  const { headline, text, items, ...rest } = props

  const [, dispatch] = useStore()

  const showLightBox = index => {
    dispatch({
      type: "SET_LIGHTBOX",
      payload: {
        open: true,
        slide: index,
        slides: items,
        options: {
          thumbs: false
        }
      }
    })
  }

  return (
    <Container {...rest}>
      <Spacer pt={{ xs: 40, sm: 60 }} pb={{ xs: 40, sm: 60 }}>
        <Edges size="md">
          {(headline || text) && (
            <Spacer mb={{ xs: 40, sm: 50 }}>
              <Introduction>
                {headline && <Typography variant="h3" children={headline} />}
                {text && (
                  <Spacer mt={20}>
                    <Typography children={text} />
                  </Spacer>
                )}
              </Introduction>
            </Spacer>
          )}

          {items && (
            <Grid container justify="space-between">
              {items.map((o, i) => {
                return (
                  <Item key={i}>
                    <Spacer mb={{ xs: 20, sm: 20 }}>
                      <ImageContainer backgroundSize={o.imageSize}>
                        {o.image && (
                          <div>
                            <BackgroundImage
                              backgroundSize={o.imageSize}
                              image={o.image}
                              onClick={() => o.imageSize === "cover" && showLightBox(i)}
                              animated={o.imageSize === "cover"}
                            />
                          </div>
                        )}
                      </ImageContainer>
                    </Spacer>

                    {o.headline && (
                      <Spacer mb={15}>
                        <Typography variant="h5" children={o.headline} />
                      </Spacer>
                    )}

                    {o.text && (
                      <Spacer pr={{ sm: 20 }}>
                        <Typography children={o.text} />
                      </Spacer>
                    )}

                    {o.button && (
                      <Spacer mt={{ xs: 20 }}>
                        <StyledButton
                          to={o.button.url}
                          children={o.button.title}
                          target={o.button.target}
                          variant="text"
                          color="primary"
                          small
                        />
                      </Spacer>
                    )}
                  </Item>
                )
              })}
              <Item style={{ height: 0, overflow: "hidden", margin: 0 }} />
              <Item style={{ height: 0, overflow: "hidden", margin: 0 }} />
            </Grid>
          )}
        </Edges>
      </Spacer>
    </Container>
  )
}

const Container = styled.div`
  background: ${theme.colors.background.light};
`

const Introduction = styled.div`
  text-align: center;
`

const Item = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 40px;

  &:nth-last-child(3) {
    margin-bottom: 0;
  }

  @media (min-width: 640px) {
    width: calc(50% - 20px);
  }

  @media (min-width: 960px) {
    width: calc(100% / 3 - 15px);
  }
`

const ImageContainer = styled.div`
  height: 200px;
  width: 100%;
  background: #fff;
  padding: ${props => (props.backgroundSize === "contain" ? "50px" : 0)};
  cursor: pointer;

  @media (min-width: 640px) {
    height: 250px;
  }

  > div {
    position: relative;
    width: 100%;
    height: 100%;
  }
`

const StyledButton = styled(Button)``

export default Features
