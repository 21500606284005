import React from "react"

// import app components
import { Edges, Textarea, Spacer } from "components"

const Text = props => {
  const { text } = props

  return (
    <Edges size="sm">
      <Spacer pt={{ xs: 40, sm: 60 }} pb={{ xs: 40, sm: 60 }}>
        <Textarea content={text} />
      </Spacer>
    </Edges>
  )
}

export default Text
