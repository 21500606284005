import React from "react"
import styled from "styled-components"
// import { Typography } from "@material-ui/core"
// import { Heart } from "mdi-material-ui"
// import { useStaticQuery, graphql } from "gatsby"

// import app components
// import { BackgroundImage } from "components"
// import InstagramIcon from "icons/instagram.svg"
// import InstagramFeed from "react-ig-feed"
// import "react-ig-feed/dist/index.css"

const SocialFeed = () => {
  // const { nodes } = useInstagramQuery()

  return (
    <Container>
      {/* {process.env.GATSBY_INSTAGRAM_KEY && (
        <InstagramFeed
          token={process.env.GATSBY_INSTAGRAM_KEY}
          // token="test"
          counter="12"
        />
      )} */}
    </Container>
  )
}

const Container = styled.div`
  background: #000;
  a:hover {
    &:before {
      content: "Link to Post";
      position: absolute;
      left: 50%;
      top: 40%;
      transform: translate(-50%, -60%);
      color: white;
      /* opacity: 0; */
      /* &:hover {
        opacity: 1;
      } */
    }
  }

  .ig-instagram-link {
    position: relative;
  }
`

// const Grid = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
//   align-items: center;
// `

// const InstaPost = styled.a`
//   position: relative;
//   cursor: pointer;
//   width: 100%;
//   height: 100vw;
//   color: #fff;
//   text-decoration: none;

//   @media (min-width: 640px) {
//     width: calc(100% / 2);
//     height: 50vw;
//   }

//   @media (min-width: 960px) {
//     width: 33.3%;
//     height: 33vw;

//     &:nth-child(3n) {
//       width: 33.4%;
//     }
//   }

//   &:hover {
//     .overlay {
//       opacity: 1;
//     }
//   }
// `

// const StyledInstagramIcon = styled(InstagramIcon)`
//   && {
//     position: absolute;
//     left: 20px;
//     top: 20px;
//     z-index: 2;
//   }
// `

// const Overlay = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: rgba(0, 0, 0, 0.5);
//   z-index: 1;
//   opacity: 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   color: white;
//   transition: ease all 0.8s;

//   span {
//     display: flex;
//     align-items: center;
//     font-weight: bold;
//     font-size: 16px;
//     svg {
//       margin-right: 8px;
//     }
//   }
// `

// const useInstagramQuery = () => {
//   const { allInstagramContent } = useStaticQuery(
//     graphql`
//       query {
//         allInstagramContent(limit: 12) {
//           nodes {
//             caption
//             permalink
//             localImage {
//               childImageSharp {
//                 fluid(maxHeight: 400, maxWidth: 400, quality: 90) {
//                   ...GatsbyImageSharpFluid
//                 }
//               }
//             }
//           }
//         }
//       }
//     `
//   )
//   return allInstagramContent
// }

export default SocialFeed
